<!-- <div class="app-block" *ngIf="!isDetail">
    <div class="container-fluid px-0">
        <div class="row">
            <div class="col-sm-12">
                <h3>Details</h3>
                <p>Tell your customers more about the subscription plan features and benefits</p>
            </div>
            <div class="col-sm-12">
                <div class="form-group required">
                    <label for="name">Title</label>
                    <input type="text" class="form-control" [(ngModel)]="name" name="name">

                </div>
                <div class="form-group">
                    <label for="Description">Description</label>
                    <textarea type="text" rows="3" class="form-control" name="description"></textarea>

                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 mt-4">
                <h4><b>Visibility and Duration</b></h4>
                <p>Set the duration for access to your plan</p>
                <div class="row">

                    <div class="col-md-6">
                        <div class="form-group required">
                            <label>Duration</label>

                            <span class="app-dropdown d-block m-0">
                                <select class="form-control" [(ngModel)]="subscriptionPlanDuration"
                                    name="subscriptionPlanDuration" required>
                                    <option value="" selected>Select</option>
                                    <option value="DAILY">
                                        Daily
                                    </option>
                                    <option value="WEEKLY">Weekly</option>
                                    <option value="MONTHLY">Monthly</option>
                                    <option value="YEARLY">Yearly</option>
                                </select>
                            </span>



                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group required">
                            <label>Trial Days</label>
                            <input type="number" class="form-control" [(ngModel)]="trialDays" name="trialDays">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


</div>

<div *ngIf="!isDetail" class="app-filters d-flex align-items-center justify-content-center p-4">
    <button type="submit" (click)="openDetail()">Next</button>
    <button type="button" routerLink="/subscription">Back</button>
</div> -->




<form #updateSubscriptionForm="ngForm" (ngSubmit)="saveSubscription(updateSubscriptionForm)">

    <div class="row">
        <div class="col-12">
            <div class="app-block">
                <!-- <form #addSecurityForm="ngForm"> -->
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group required">
                            <label>Plan name</label>
                            <input type="text" class="form-control" maxlength="25" [(ngModel)]="name" name="name"
                                required>
                            <ng-container
                                *ngIf="updateSubscriptionForm.controls['name'].invalid && (updateSubscriptionForm.controls['name'].touched || submitted)">
                                <p style="margin-bottom: 0px;" class="error"
                                    *ngIf="updateSubscriptionForm.controls['name'].hasError('required')">
                                    Plan Name is required</p>
                            </ng-container>
                            <!-- <p class="para-text">{{ 50 - (name?.length || 0) }} characters remaining</p> -->

                        </div>
                    </div>

                    <div class="col-md-4 col-sm-12">
                        <div class="form-group required" style="position: relative;">
                            <label>Edit Price</label>
                            <p class="detailD">$</p>

                            <input type="text" class="form-control" [(ngModel)]="price" (ngModelChange)="changePrice()"
                                required name="price" placeholder="0.00">
                            <ng-container
                                *ngIf="updateSubscriptionForm.controls['price'].invalid && (updateSubscriptionForm.controls['price'].touched || submitted)">
                                <p class="error">Edit price is required</p>
                            </ng-container>

                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <!-- <div class=" mb-4" style="position: relative;">
                            <label>Recommended Amount</label>
                            <p class=" detailD">$</p>
                            <input type="text" readonly class="form-control " [value]="recommendedPrice"
                                placeholder="0.00">

                        </div> -->
                        <div class="form-group required" style="position: relative;">
                            <label>Trial Days</label>
                            <input type="number" class="form-control" [(ngModel)]="trialDays" name="trialDays" required>
                            <ng-container
                                *ngIf="updateSubscriptionForm.controls['trialDays'].invalid && (updateSubscriptionForm.controls['trialDays'].touched || submitted)">
                                <p class="error"
                                    *ngIf="updateSubscriptionForm.controls['trialDays'].hasError('required')">Trial Days
                                    are required
                                </p>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <div class="app-block mt-20" style="padding: 30px !important;">
                <div style="display: flex;">
                    <p class="info-title">Add Details (from list) to Selected Subscription Plan</p>
                    <p style="width: 38.5%;"></p>
                    <p>Total Recommended Price = ${{recommendedPrice ? recommendedPrice.toFixed(3) : '0'}} </p>
                </div>

                <!-- NEW TABLE -->

                <!-- <div class="card" style="border: none;">
                                    <p-table [value]="ListPlanDetails" [autoLayout]="true">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th style="width: 25%">Details</th>
                                                <th style="text-align: center; width: 25%">View</th>
                                                <th style="text-align: center; width: 25%">Value/Quantity</th>
                                                <th style="text-align: center; width: 25%">Recommended Price</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-template>
                                            <tr class="table-padding">
                                                <td>{{template.name}}</td>
                                                <td style="text-align: center;">
                                                    <label class="switch-input">
                                                    <input type="checkbox" [(ngModel)]="template.isView" (ngModelChange)="updateParentView(template)"
                                                        [name]="'isView-' + template.name">

                                                        <span class="slider" style="width:34px !important"></span>
                                                    </label>
                                                </td>
                                                <td style="text-align: center;">
                                                    <div class="form-group mb-0 inputField">
                                                        <input class="form-control amount_input"
                                                            *ngIf="template.name === 'Live Streaming' || template.name === 'Networks'"
                                                            [(ngModel)]="template.quantity" type="text" [name]="template.name"
                                                            [id]="template.name">
                                                    </div>
                                                </td>
                                                <td *ngIf="(!template.subFeature)" style="text-align: center;">{{'$'
                                                    +
                                                    template.defaultPrice}}
                                                </td>
                                                <td *ngIf="(template.subFeature)" style="text-align: center;">
                                                </td>

                                            </tr>
                                            <tr *ngIf="(template.subFeature && template.subFeature.length > 0) && template.isView">
                                                <td colspan="4" style="padding: 0px;">
                                                    <p-table [value]="template.subFeature">
                                                        <ng-template pTemplate="body" let-subFeature>
                                            <tr>
                                                <td style="padding-left: 30px;"><span
                                                        style="padding-right: 5px;">&bull;</span>{{subFeature.name}}
                                                </td>

                                                <td style="text-align: center;">
                                                    <label class="switch-input">
                                                        <input type="checkbox" [(ngModel)]="subFeature.isView"
                                                            [name]="'isView-' + subFeature.name"
                                                            (ngModelChange)="calculateRecommendedPrice()">
                                                        <span class="slider" style="width:34px !important"></span>
                                                    </label>
                                                </td>
                                                <td *ngIf="template.name === 'Music' || template.name === 'Mobile App' || template.name === 'TV App' || template.name === 'Videos'">

                                                </td>
                                                    <td style="text-align: center;" *ngIf="template.name === 'Channels'">
                                                        <div class="form-group mb-0 inputField">
                                                            <input class="form-control amount_input" [(ngModel)]="template.quantity"
                                                                type="text" [name]="template.name" [id]="template.name">
                                                        </div>
                                                    </td>

                                                <td style="text-align: center;">{{subFeature.defaultPrice ? '$' + subFeature.defaultPrice :
                                                    '-'}}
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    </td>
                                    </tr>
                                    </ng-template>
                                    </p-table>
                                </div> -->



                <!-- NEW TABLE -->
                <div class="card main-table">
                    <p-table [value]="ListPlanDetails" sortMode="single" rowGroupMode="subheader"
                        dataKey="template.name" groupRowsBy="template.name">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 25%;padding-left: 34px;">Details</th>
                                <th style="width: 25%;text-align: center;">View</th>
                                <th style="width: 25%;text-align: center;">Value/Quantity</th>
                                <th style="width: 25%;text-align: center;">Recommended Price</th>
                            </tr>
                        </ng-template>


                        <ng-template pTemplate="body" let-template>
                            <tr>
                                <td>
                                    <button *ngIf="template.subFeature" type="button" pButton pRipple
                                        style="padding: 0px; left: -11px;"
                                        class="p-button-text p-button-rounded p-button-plain mr-2"
                                        (click)="expandRow(template)"
                                        [icon]="!template.expanded ? 'pi pi-chevron-right' : 'pi pi-chevron-down'"></button>
                                    <span class="font-bold ml-2"
                                        [ngStyle]="{'margin-left': template.subFeature ? '-0.7vw' : '1.8vw'} ">{{template.name}}</span>
                                </td>
                        <td style="text-align: center;">
                            <label class="switch-input"
                             [tooltip]="template.name === 'Storage' || template.name === 'Settings'
                                             || template.name === 'Dashboard' || template.name === 'Subscription'
                                             || template.name === 'Contact Us' || template.name === 'Contacts' || template.name === 'VOD Data Usage'  ? 'This feature will always remain On' : ''">
                                <input type="checkbox" [(ngModel)]="template.isView" [disabled]="template.name === 'Storage' || template.name === 'Settings'
                                               || template.name === 'Dashboard' || template.name === 'Subscription'
                                               || template.name === 'Contact Us' || template.name === 'Contacts'  || template.name === 'VOD Data Usage'"
                                    (ngModelChange)="updateParentView(template)" [name]="'isView-' + template.name"
                                 >

                                <span class="slider" style="width:34px !important"></span>
                            </label>
                        </td>
                                <td style="text-align: center;">
                                    <!-- <div class="form-group mb-0 inputField">
                                        <input class="form-control amount_input" style="height: 25px;"
                                            *ngIf="template.name === 'Live Streaming' && template.isView" [(ngModel)]="template.quantity"
                                            type="number" (ngModelChange)="calculateRecommendedPrice()"
                                            [name]="template.name" [id]="template.name">
                                    </div> -->
                                    <!-- <div class="form-group mb-0 inputField" *ngIf="template.name === 'Storage' ">
                                        <div class="app-dropdown">
                                            <select class="form-control" [(ngModel)]="selectedStorageOption"
                                                [name]="selectedStorageOption" (change)="selectStorage($event)"
                                                SelectStorageDropdown>
                                                <option value="500gb">500 GB</option>
                                                <option value="1tb">1 TB</option>
                                                <option value="5tb">5 TB</option>
                                            </select>
                                        </div>

                                    </div> -->

<ng-container *ngIf="template.name === 'Storage'">
    <div class="app-dropdown">
        <select class="form-control" [(ngModel)]="dataUsagePricingInfoId" name="dataUsagePricingInfoId"
(ngModelChange)="selectStorage($event)"
            >
            <option *ngFor="let option of storageOptions"
                    [value]="option.dataUsagePricingInfoId">{{ option.name }}
                ({{option.storageQuantity}}{{option.unit}})</option>
        </select>
    </div>
</ng-container>

<ng-container *ngIf="template.name === 'VOD Data Usage'">
    <div class="app-dropdown">
        <select class="form-control" [(ngModel)]="dataUsagePricingVODInfoId" name="dataUsagePricingVODInfoId"
            (ngModelChange)="selectVOD($event)">
            <option *ngFor="let option of vodOptions"   [value]="option.dataUsagePricingInfoId">{{ option.name }}
                ({{option.storageQuantity}}{{option.unit}})</option>
        </select>
    </div>
</ng-container>



                                </td>

<td *ngIf="(!template.subFeature  )" style="text-align: center;">{{'$'
    +
    template.defaultPrice}}
</td>
<!-- <td *ngIf="(template.name === 'Storage')" style="text-align: center;">
    {{'$' + byDefaultSelectedPrice}}
</td>
<td *ngIf="(template.name === 'Data Usage')" style="text-align: center;">
    {{'$' + byDefaultSelectedPriceVOD}}
</td> -->
                    <!-- <td *ngIf="template.name === 'Storage'" style="text-align: center;">
                        <ng-container *ngIf="selectedStorageOption; ">
                            {{ '$' + selectedStoragePrice }}
                        </ng-container>

                    </td> -->


                                <td *ngIf="(template.subFeature)" style="text-align: center;">
                                </td>


                            </tr>
                            <!-- <ng-template > -->
                            <ng-container *ngIf="template.isView && template.expanded">
                                <ng-container *ngIf="template.subFeature && template.expanded">
                                    <ng-container *ngFor="let subFeature of template.subFeature">
                                        <tr>
                                            <td style="padding-left: 3.2rem;">
                                                {{subFeature.name}}
                                            </td>
                                            <td style="text-align: center;">
                                                <label class="switch-input" [tooltip]="subFeature.name === 'Networks' && subFeature.isDisable ? 'Please turn on Custom Channel or M3U Channel to turn on Network.' : subFeature.name === 'Custom Channel' && subFeature.isDisable ? 'Please turn on the Videos (mp4, m4v, mov, wmv, flv, mkv, avi) to turn on Custom Channel' : ''">
                                                    <input type="checkbox" [disabled]="subFeature.isDisable ||
                                                    subFeature.name === 'Live Stream Devices' ||
                                                    subFeature.name === 'Live Stream Viewers Count' ||
                                                    subFeature.name === 'Live Stream Data Usage'" [(ngModel)]="subFeature.isView"
                                                        [name]="'isView-' + subFeature.name"
                                                        (ngModelChange)="calculateRecommendedPrice()">
                                                    <span class="slider" style="width:34px !important"></span>
                                                </label>
                                            </td>
                                            <td
                                                *ngIf="template.name === 'Music' || template.name === 'Mobile App' || template.name === 'TV App' || template.name === 'Videos'">
                                            </td>
                                            <td style="text-align: center;" *ngIf="template.name === 'Channels' && template.isView">
                                                <div class="form-group mb-0 inputField">
                                                    <!-- <input style="height: 25px;" class="form-control amount_input"
                                                        [(ngModel)]="template.quantity" type="number"
                                                        [name]="template.name" [id]="template.name"> -->
                                                                    <input style="height: 25px;" class="form-control amount_input" *ngIf="subFeature.isView" [(ngModel)]="subFeature.quantity" type="number"
                                                                   (ngModelChange)="calculateRecommendedPrice()"
                                                                   [name]="subFeature.name" [id]="subFeature.name">


                                                </div>
                                            </td>

                                            <td style="text-align: center;" *ngIf="subFeature.name === 'Live Stream Devices' && template.isView">
                                                <div class="form-group mb-0 inputField margin-center" style="margin-left:0px; width:188px;">
                                                    <input class="form-control amount_input" style="height: 38px; margin-left: -5px;" [(ngModel)]="subFeature.quantity"
                                                        type="number" (ngModelChange)="calculateRecommendedPrice()"
                                                        [name]="subFeature.name" [id]="subFeature.name">
                                                </div>
                                            </td>
                                            <td style="text-align: center;" *ngIf="subFeature.name === 'Live Stream Viewers Count' && template.isView">
                                                <div class="app-dropdown">
                                                    <select class="form-control" [(ngModel)]="selectedViewersCount" name="livedataUsageViewers" style="width:188px">
                                                        <option *ngFor="let option of viewerCountList;" [value]="option.id">{{ option.name }}</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td style="text-align: center;" *ngIf="subFeature.name === 'Live Stream Data Usage' && template.isView">
                                                <div class="app-dropdown">
                                                    <select class="form-control" [(ngModel)]="selectedLiveDataUsage" name="livedataUsage" style="width:188px"
                                                        (ngModelChange)="selectLiveStream($event)">
                                                        <option *ngFor="let option of liveStreamDataUsageOptions"   [value]="option.dataUsagePricingInfoId">{{ option.name }}
                                                            ({{option.storageQuantity}}{{option.unit}})</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td style="text-align: center;">{{subFeature.defaultPrice ? '$' +
                                                subFeature.defaultPrice :
                                                '-'}}
                                            </td>
                                        </tr>


                                        <!-- ... other columns ... -->
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <!-- </ng-template> -->

                        </ng-template>


                    </p-table>
                </div>


            </div>
        </div>
    </div>
    <!-- </form> -->
    <div class="container text-center mt-20">
        <button type="submit" class="btn btn-white mr-10">Save</button>
        <button type="button" class="btn btn-white" routerLink="/subscription">Back</button>
    </div>
</form>

<p-dialog *ngIf="showInfoModal" header="" [(visible)]="showInfoModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div>
        <p>If you want this change effect on all Organizations than select <b>"All Organizations"</b> OR if you want this change effect for upcoming Organizations(new Organizations) than select <b>"Upcoming Organizations"</b></p>
    </div>
    <p-footer>
        <button type="button" class="btn btn-white" (click)="selectOption('yes',updateSubscriptionForm)">All Organizations</button>
        <button type="button" class="btn btn-white" (click)="selectOption('no',updateSubscriptionForm)">Upcoming Organizations</button>
    </p-footer>
</p-dialog>