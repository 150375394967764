import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { AuthenticateModule } from './modules/authenticate/authenticate.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { AuthGuard } from './auth-guards/auth.guard';
import { HttpClientModule } from '@angular/common/http';
import { EditSubscriptionComponent } from './components/subscription/edit-subscription/edit-subscription.component';
import { SubscriptionDetailComponent } from './components/subscription/subscription-detail/subscription-detail.component';
import { TableModule } from 'primeng/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GivingStatementComponent } from './components/giving/giving-statement/giving-statement.component';
import { GivingStatementListComponent } from './components/giving/giving-statement-list/giving-statement-list.component';
import { GivingStatementDetailsComponent } from './components/giving/giving-statement-details/giving-statement-details.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FolderMusicComponent } from './components/media/music/folder-music/folder-music.component'
import { EncrDecrService } from './services/shared/encrDecr.service';
import { NgxScrollPositionRestorationModule } from 'ngx-scroll-position-restoration';
import { ChartsModule } from 'ng2-charts';
import { TranscodingComponent } from './components/transcode/transcoding/transcoding.component';
import { DashboardResolver } from './components/dashboard/dashboard.service';
import { SubscriptionDetailResolver } from './components/subscription/subscription-detail/subscription-detail.resolver';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { PushNotificationSubscribersComponent } from './components/apps/push-notification/push-notification-subscribers/push-notification-subscribers.component';
import { AppDownloadsComponent } from './components/apps/analytics/app-downloads/app-downloads.component';
import { CreateCustomPlanComponent } from './components/subscription/create-custom-plan/create-custom-plan.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SubscriptionTemplateComponent } from './components/subscription-template/subscription-template.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SubscriptionComponent,
    EditSubscriptionComponent,
    SubscriptionDetailComponent,
    TranscodingComponent,
    PushNotificationSubscribersComponent,
    AppDownloadsComponent,
    CreateCustomPlanComponent,
    SubscriptionTemplateComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthenticateModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    MatProgressSpinnerModule,
    NgxScrollPositionRestorationModule.forRoot(),
    ChartsModule,
    NgxYoutubePlayerModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  providers: [
    AuthGuard,
    EncrDecrService,
    DashboardResolver,
    SubscriptionDetailResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
