import { SubscriptionComponent } from './components/subscription/subscription.component';
import { InitialComponent } from './components/shared/initial/initial.component';
import { ErrorHandler, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './auth-guards/auth.guard';
import { EditSubscriptionComponent } from './components/subscription/edit-subscription/edit-subscription.component';
import { SubscriptionDetailComponent } from './components/subscription/subscription-detail/subscription-detail.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DashboardResolver } from './components/dashboard/dashboard.service';
import { SubscriptionDetailResolver } from './components/subscription/subscription-detail/subscription-detail.resolver';
import { CreateCustomPlanComponent} from './components/subscription/create-custom-plan/create-custom-plan.component'
import { GlobalErrorHandler } from './GlobalErrorHandler';

const routes: Routes = [
  {
    path: "",
    component: InitialComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        resolve: { data: DashboardResolver },
        data: {
          header: "Dashboard",
        },
      },
      {
        path: "customer-module",
        loadChildren: () =>
          import("./modules/customer/customer.module").then(
            (m) => m.CustomerModule
          ),
      },
      {
        path: "email-templates-module",
        loadChildren: () =>
          import("./modules/email-templates/email-templates.module").then(
            (m) => m.EmailTemplatesModule
          ),
      },
      {
        path: "subscription",
        component: SubscriptionComponent,
        data: {
          header: "Subscription",
        },
        children: [
          {
            path: "",
            component: SubscriptionDetailComponent,
            resolve: { data: SubscriptionDetailResolver },
            data: {
              header: "Subscription",
            },
          },
          {
            path: "edit-subscription/:id/:name",
            component: EditSubscriptionComponent,
            data: {
              header: "Subscription",
            },
          },
          {
            path: "create-custom-plan",
            component: CreateCustomPlanComponent,
            data: {
              header: "Subscription",
            },
          },
          {
            path: "edit-custom-plan/:id/:name",
            component: CreateCustomPlanComponent,
            data: {
              header: "Subscription",
            },
          },
        ],
      },
      {
        path: "accounting-module",
        loadChildren: () =>
          import("./modules/accounting/accounting.module").then(
            (m) => m.AccountingModule
          ),
      },
      {
        path: "admin-setting-module",
        loadChildren: () =>
          import("./modules/admin-setting/admin-setting.module").then(
            (m) => m.AdminSettingModule
          ),
      },
      {
        path: "onboarding-module",
        loadChildren: () =>
          import("./modules/onboarding/onboarding.module").then(
            (m) => m.OnBoardingModule
          ),
      },

      {
        path: "requestcutomer-module",
        loadChildren: () =>
          import("./modules/requestcustomer/requestcustomer.module").then(
            (m) => m.RequestCustomerModule
          ),
      },
      {
        path: "transcoding-module",
        loadChildren: () =>
          import("./modules/transcoding/transcoding.module").then(
            (m) => m.TranscodingModule
          ),
      },
      {
        path: "security-module",
        loadChildren: () =>
          import("./modules/security/security.module").then(
            (m) => m.SecurityModule
          ),
      },
      {
        path: "media-module",
        loadChildren: () =>
          import("./modules/media/media.module").then((m) => m.MediaModule),
      },
      {
        path: "stock-module",
        loadChildren: () =>
          import("./modules/upload-stock/upload-stock.module").then(
            (m) => m.UploadStockModule
          ),
      },
      {
        path: "library-module",
        loadChildren: () =>
          import("./modules/library/library.module").then(
            (m) => m.LibraryModule
          ),
      },
      {
        path: "apps-module",
        loadChildren: () =>
          import("./modules/apps/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "events-module",
        loadChildren: () =>
          import("./modules/events/events.module").then((m) => m.EventsModule),
      },
      {
        path: "settings-module",
        loadChildren: () =>
          import("./modules/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: "organization-email-templates-module",
        loadChildren: () =>
          import(
            "./modules/organization-email-templates/organization-email-templates.module"
          ).then((m) => m.OrganizationEmailTemplatesModule),
      },
      {
        path: "contacts-module",
        loadChildren: () =>
          import("./modules/contacts/contacts.module").then(
            (m) => m.ContactsModule
          ),
      },
      {
        path: "subscription-module",
        loadChildren: () =>
          import(
            "./modules/subscription-module/subscription-module.module"
          ).then((m) => m.SubscriptionModuleModule),
      },

      {
        path: "contactus-module",
        loadChildren: () =>
          import("./modules/contactus/contactus-module.module").then(
            (m) => m.ContactusModuleModule
          ),
      },
      {
        path: "social-module",
        loadChildren: () =>
          import("./modules/social/social.module").then((m) => m.SocialModule),
      },
      {
        path: "giving-module",
        loadChildren: () =>
          import("./modules/giving/giving.module").then((m) => m.GivingModule),
      },
      {
        path: "web-module",
        loadChildren: () =>
          import("./modules/web/web.module").then((m) => m.WebModule),
      },
      {
        path: "channel-module",
        loadChildren: () =>
          import("./modules/channel/channel.module").then(
            (m) => m.ChannelModule
          ),
      },
      {
        path: "payment-module",
        loadChildren: () =>
          import("./modules/payment/payment.module").then(
            (m) => m.PaymentModule
          ),
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./modules/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: "AI",
        loadChildren: () =>
          import("./modules/chat-support/chat-support.module").then(
            (m) => m.ChatSupportModule
          ),
      },
      {
        path: "data-usage-module",
        loadChildren: () =>
          import("./modules/data-usage/data-usage.module").then(
            (m) => m.DataUsageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'disabled' })],
  exports: [RouterModule],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
})
export class AppRoutingModule {}
