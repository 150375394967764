// import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { retry } from 'rxjs/operators';
import { appConfig } from 'src/app/app.config'
import { ResourceService } from '../resource/resource.service';
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaServicesService {

  panelState = new Subject();
  panel$ = this.panelState.asObservable();

  updateSocialLeadFormTable = new Subject()
  updateSocialLeadFormTable$ = this.updateSocialLeadFormTable.asObservable()

  constructor(
    public resourceService: ResourceService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  updatePanel(state) {
    this.panelState.next(state);
  }

  updateSocialLeadTable(data) {
    this.updateSocialLeadFormTable.next(data)
  }

  // add social media

  addSocialMedia(data) {
    const addSocialMediaURL = `${appConfig.socialMedia}`
    return this.resourceService.postData(addSocialMediaURL, data)
  }

  // lead form list

  getLeadFormList(page, size, search) {

    const filter = search ? `&searchKey=${search}` : ''

    const getleadFormListURL = `${appConfig.socialMedia}/getLeadFormList?page=${page}${filter}&size=${size}`
    return this.resourceService.getData(getleadFormListURL)
  }

  // create Lead form

  createLeadForm(data) {
    const createLeadFormURL = `${appConfig.socialMedia}/leadForm`
    return this.resourceService.postData(createLeadFormURL, data)
  }

  // delete Lead Form

  deleteLeadForm(id) {
    const deleteLeadFormURL = `${appConfig.socialMedia}?id=${id}`
    return this.resourceService.deleteData(deleteLeadFormURL)
  }

  // update Lead Form

  updateLeadForm(id, data) {
    const updateLeadFormURL = `${appConfig.socialMedia}/${id}`
    return this.resourceService.putData(updateLeadFormURL, data)
  }

  //updated leads data

  getLeadsUpdatedData(id) {
    const url = `${appConfig.socialMedia}/leadsData?formId=${id}`
    return this.resourceService.getData(url)
  }

  // get Lead Form By Id

  getLeadFormById(id) {
    const getLeadFormByIdURL = `${appConfig.socialMedia}/${id}`
    return this.resourceService.getData(getLeadFormByIdURL)
  }

  // get Lead List

  getLeadList(page, size, id) {
    const getLeadListURL = `${appConfig.socialMedia}/getLeadList?formId=${id}&page=${page}&size=${size}`
    return this.resourceService.getData(getLeadListURL)
  }

  // post message

  postMessage(data) {

    // const doc = document ? `documentId=${document}&` : ''
    const postMessageURL = `${appConfig.oauth2Login}/postMessage`
    return this.resourceService.postData(postMessageURL, data)
  }

  // save post Info

  savePostInfo(data) {
    const savePostInfoURL = `${appConfig.oauth2Login}/savePostInfo`
    return this.resourceService.postData(savePostInfoURL, data)
  }

  // get token

  getFacebookTokenFromCode(code, type) {
    const getTokenFromCode = `${appConfig.oauth2Login}/userAccessToken?code=${code}&socialMediaType=${type}`
    return this.resourceService.getData(getTokenFromCode)
  }

  //get id from token

  getFacebookIdFromToken(code) {
    const getFacebookId = `${appConfig.oauth2Login}/facebookUserId?accessToken=${code}`
    return this.resourceService.getData(getFacebookId)
  }

  //get Token for pages

  getTokenForPages(token, id) {
    const getPageToken = `${appConfig.oauth2Login}/facebookPages?userAccessToken=${token}&userId=${id}`
    return this.resourceService.getData(getPageToken)
  }

  //send pageAccessToken

  sendPageAccessToken(data) {
    const sendPageAccessTokenUrl = `${appConfig.oauth2Login}/saveAppToken`
    return this.resourceService.postData(sendPageAccessTokenUrl, data)
  }

  //get page Data

  getPageData() {
    const pageDataUrl = `${appConfig.oauth2Login}/getAppToken`
    return this.resourceService.getData(pageDataUrl)
  }


  getPageDataForConnectedSocialMedia() {
    const pageDataUrl = `${appConfig.oauth2Login}/getAppToken?isConnected=${true}`
    return this.resourceService.getData(pageDataUrl)
  }

  //get page profile picture

  getPageProfilePicture(id) {
    const profileImageURL = `${appConfig.oauth2Login}/pageProfile?pageId=${id}`
    return this.resourceService.getData(profileImageURL)
  }


  getVideoDataById(id) {
    const videoDataByIdURL = `${appConfig.video}/${id}`
    return this.resourceService.getData(videoDataByIdURL)
  }



  // delete page token

  deletePageToken(id) {
    const deletePageTokenURL = `${appConfig.oauth2Login}?${id}`
    return this.resourceService.deleteData(deletePageTokenURL)
  }

  getInstaAccessToken(code) {
    const getInstaAccessToken = `${appConfig.oauth2Login}/instagramToken?verificationCode=${code}`;
    return this.resourceService.postData(getInstaAccessToken)
  }

  getInstagramLongLivedToke(token) {
    const getInstaLongLivedToken = `${appConfig.oauth2Login}/instagramLongLivedToken?accessToken=${token}`;
    return this.resourceService.getData(getInstaLongLivedToken)
  }

  getInstaUserIdConnectedToFb(fbPageId, pageAccToken) {
    const getInstaUserIdConnectedToFb = `${appConfig.oauth2Login}/instagramUserId?facebookPageId=${fbPageId}&pageAccessToken=${pageAccToken}`;
    return this.resourceService.getData(getInstaUserIdConnectedToFb);
  }

  getInstaUserDetails(accToken, userId) {
    const getInstaUserDetails = `${appConfig.oauth2Login}/instagramUserDetails?facebookAccessToken=${accToken}&instagramUserId=${userId}`;
    return this.resourceService.getData(getInstaUserDetails)
  }

  getTwitterAccessToken(code) {
    const getTwitterAccessTokenURL = `${appConfig.oauth2Login}/twitterToken?verificationCode=${code}`;
    return this.resourceService.postData(getTwitterAccessTokenURL);
  }

  getTwitterAccessTokenOauth(code,oauthToken,oauthVerifier) {
    if(code){
      var getTwitterAccessTokenURL = `${appConfig.oauth2Login}/oauthTokenSecret?code=${code}`;
    }
    else{
      var getTwitterAccessTokenURL = `${appConfig.oauth2Login}/oauthTokenSecret?oauthToken=${oauthToken}&oauthVerifier=${oauthVerifier}`;
    }
    return this.resourceService.postData(getTwitterAccessTokenURL);
  }

  scheduledPostOnSocialMedia(data,id) {
    if(id){
      console.log(id);
      
      const url = `${appConfig.oauth2Login}/${id}`;
      return this.resourceService.putData(url, data)
    }
    else{
      const schedulePostURL = `${appConfig.oauth2Login}/createSocialMediaPost`;
      return this.resourceService.postData(schedulePostURL, data)
    }
  
  }

  // get twitter authorization url

  getTwitterAuthUrl(boolean) {
    const getTwitterAuthURL = `${appConfig.oauth2Login}/authorizationUrl?isOauth2=${boolean}`;
    return this.resourceService.postData(getTwitterAuthURL)
  }

  // get Twitter access

  getTwitterAccess(oauthToken, oauthVerifier) {
    const getTwitterAccess = `${appConfig.oauth2Login}/oauthTokenSecret?oauthToken=${oauthToken}&oauthVerifier=${oauthVerifier}`;
    return this.resourceService.postData(getTwitterAccess)
  }

  // get recent posts on Social media

  getRecentPosts() {
    const getRecentPostURL = `${appConfig.oauth2Login}/recentPosts`;
    return this.resourceService.getData(getRecentPostURL)
  }

  // get recent posts on Social media

  getScheduledPosts(page, size) {
    const getRecentPostURL = `${appConfig.oauth2Login}/recentPosts?page=${page}&scheduledPosts=true&size=${size}`;
    return this.resourceService.getData(getRecentPostURL)
  }

  // get account statistics

  getAccountStatistics(page, size) {
    const getAccountStatisticsURL = `${appConfig.oauth2Login}/accountStatistics?page=${page}&size=${size}`;
    return this.resourceService.getData(getAccountStatisticsURL)
  }

  //subscribe to page

  subscribePage(id) {
    const url = `${appConfig.webhook}/subscribeLeadGen?socialMediaTokenInfoId=${id}`;
    return this.resourceService.postData(url)
  }

  getLeadListByPagination(page, size, id, search) {

    const filter = search ? `&searchKey=${search}` : ''

    const getleadFormListURL = `${appConfig.socialMedia}/leadAdFormList?page=${page}${filter}&size=${size}&socialMediaTokenInfoId=${id}`
    return this.resourceService.getData(getleadFormListURL)
  }

  getLeadFormListFacebook(id) {
    const url = `${appConfig.socialMedia}/leadAdForms?socialMediaTokenInfoId=${id}`
    return this.resourceService.getData(url)
  }

  getWebhookNotification(page, size) {
    const url = `${appConfig.webhook}/webhookData?page=${page}&size=${size}`
    return this.resourceService.getData(url)
  }

  //post lead as contact

  postLeadAsContact(id) {
    const url = `${appConfig.socialMedia}/register?leadId=${id}`;
    return this.resourceService.postData(url)
  }


  // public promptForVideo(): Promise<File> {
  //   return new Promise<File>((resolve, reject) => {
  //     // make file input element in memory
  //     const fileInput: HTMLInputElement = this.document.createElement('input');
  //     fileInput.type = 'file';
  //     fileInput.accept = 'video/*';
  //     fileInput.setAttribute('capture', 'camera');
  //     // fileInput['capture'] = 'camera';
  //     fileInput.addEventListener('error', event => {
  //       reject(event.error);
  //     });
  //     fileInput.addEventListener('change', event => {
  //       resolve(fileInput.files[0]);
  //     });
  //     // prompt for video file
  //     fileInput.click();
  //   });
  // }

  // public generateThumbnail(videoFile: Blob): Promise<string> {
  //   const video: HTMLVideoElement = this.document.createElement('video');
  //   const canvas: HTMLCanvasElement = this.document.createElement('canvas');
  //   const context: CanvasRenderingContext2D = canvas.getContext('2d');
  //   return new Promise<string>((resolve, reject) => {
  //     canvas.addEventListener('error',  reject);
  //     video.addEventListener('error',  reject);
  //     video.addEventListener('canplay', event => {
  //       canvas.width = video.videoWidth;
  //       canvas.height = video.videoHeight;
  //       context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  //       resolve(canvas.toDataURL());
  //     });
  //     if (videoFile.type) {
  //       video.setAttribute('type', videoFile.type);
  //     }
  //     video.preload = 'auto';
  //     video.src = window.URL.createObjectURL(videoFile);
  //     video.load();
  //   });
  // }

  getPostStatus(id) {
    const url = `${appConfig.oauth2Login}/postStatus?socialMediaPostInfoDetailId=${id}`;
    return this.resourceService.getData(url);
  }

  getConnectingUrl() {
    const url = `${appConfig.oauth2Login}/connectingUrl`;
    return this.resourceService.getData(url)
  }

  //disconnect/reconnect
  disconnectReconnectPage(id, socialMediaAccountStatus) {
    const url = `${appConfig.oauth2Login}?id=${id}&socialMediaAccountStatus=${socialMediaAccountStatus}`;
    return this.resourceService.putData(url)
  }
 


  //sync social media
  syncSocialMedia(id) {
    const url = `${appConfig.oauth2Login}/sync?id=${id}`;
    return this.resourceService.putData(url)
  }

  //get schedule data by id
  getScheduleDataByID(id){
    const getScheduleDataByIDUrl = `${appConfig.oauth2Login}/${id}`;
    return this.resourceService.getData(getScheduleDataByIDUrl);
  }

  //delete schedule post
  deleteSchedulePosts(id) {
    const url = `${appConfig.oauth2Login}/${id}`;
    return this.resourceService.deleteData(url)
  }

  //edit schedule post
  editSchedulePosts(id) {
    const url = `${appConfig.oauth2Login}/${id}`;
    return this.resourceService.putData(url)
  }

}
