<div class="app-block app-block-form1 video-item">
    <div class="app-block-title" style="position: relative;">
                <p class="header-title" style="position: absolute;left: 2%;width: 171px;">Subscribe Email</p>

        <p class="header-title" style="position: absolute;left: 20%;width: 171px;">App Download date & time</p>
        <p class="header-title" style="position: absolute;left: 36%;">Device Id</p>
        <p class="header-title" style="position: absolute;left: 48%;">Device Model</p>
        <p class="header-title" style="position: absolute;left: 63%;">Device OS</p>
        <p class="header-title" style="position: absolute;left: 75%;">App version</p>
        <p class="header-title" style="position: absolute;left: 89%;">User Status</p>

    </div>
    <ul class="recent-item-list m-0">
        <li *ngFor="let recent of pages" style="position: relative;" >
            <div class="d-flex align-items-center  route-to-width ">
                    <div class="p-2" style="position: absolute;left: 1.5%;">
                        <p class="m-0 ellipsis-text-content" title="{{recent.email}}">
                            {{recent.email ? recent.email : 'Unknown User'}} </p>
                    </div>
                <div class="p-2" style="position: absolute;left: 19.5%;" >
                <p class="m-0">
                    {{ recent.createdDate ? (recent.createdDate | date : 'M-d-YYYY h:mm a') : '--' }}
                </p>

                </div>
                <div class="p-2 series-position" style="position: absolute;left: 34.5%;" >
                    <p class=" title-container ellipsis-text-content m-0" title="{{recent.deviceId}}">{{recent.deviceId ? recent.deviceId : '--'  }}</p>
                </div>

                <div class="p-2 plan-position" style="position: absolute;left: 48%;" >

                    <p class=" ellipsis-text-content title-container m-0">{{recent.deviceModel ? recent.deviceModel : '--' }}</p>

                </div>
                <div class="p-2 plan-position" style="position: absolute;left: 62.5%;" >
                    <p class=" ellipsis-text-content title-container m-0">{{recent.deviceOS ? recent.deviceOS : '--' }}</p>
                </div>

                <div class="p-2" style="position: absolute;left: 76.5%;" >
                    <p class=" title-container m-0">{{recent.appVersion ? recent.appVersion : '--' }}</p>
                </div>
                <div class="p-2" style="position: absolute;left: 89%;">
                    <p class=" title-container m-0" *ngIf="recent.email">Registered</p>
                    <p class=" title-container m-0" *ngIf="!recent.email" >Not Registered</p>

                </div>
                <div class="recent-item-icons" style="position: absolute;left: 97%;">
                    <div class="d-flex align-items-center">
                        <div class="position-relative action-icons">
                            <span>
                                <img *ngIf="recent.email" class="view_img pointer" (click)="openViewModal(recent)" style="margin-left: 10px;" tooltip="View"
                                    src="./../../../../assets/images/library/view.svg" alt="">

                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li *ngIf="!pages.length" class="no-media">
            <div class="d-flex align-items-center">
                <div class="recent-info" style="margin-left: 16px;">
                    <span>No subscriber found.</span>
                </div>
            </div>
        </li>
    </ul>

    <div class="d-flex justify-content-between align-items-center paginator-top w-100">

        <p-paginator class="custom_paginator" [rows]="rowCount" [showCurrentPageReport]="true" [first]="first"
            currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            [rowsPerPageOptions]="[10,20,25,50, 100]" (onPageChange)="loadCustomers($event)"
            [totalRecords]="totalRecords" pageLinkSize="3"></p-paginator>

    </div>


</div>

<div class="container text-center mt-20">
    <!-- <button type="submit" class="btn btn-white mr-10">Save</button> -->
    <button type="button" class="btn btn-white" (click)="goBack()">Back</button>
</div>

    <p-dialog *ngIf="viewModal" header="View User Details" [(visible)]="viewModal" [modal]="true" [draggable]="false"
        [resizable]="false" class="view-popup">

        <div style="position: relative;width: 559px;">
            <div class="py-0" style="width: 544px;" [(ngModel)]="selectedUser">
                <div class="d-flex giving-details-div justify-content-start w-100 popupPadding" >
                    <p class="list-heading w-50 mb-0">User Name </p>
                    <p class="list-heading w-50 mb-0"> {{selectedUser.firstName}} {{selectedUser.lastName}}</p>
                </div>
                <div class="d-flex giving-details-div justify-content-start w-100 popupPadding">
                    <p class="list-heading w-50 mb-0"> Email </p>
                    <p class="list-heading w-50 mb-0">{{selectedUser.email}}</p>
                </div>
                <div class="d-flex giving-details-div justify-content-start w-100 popupPadding">
                    <p class="list-heading w-50 mb-0"> Mobile </p>
                    <p class="list-heading w-50 mb-0">{{selectedUser.mobile}}</p>
                </div>
                <div class="d-flex giving-details-div justify-content-start w-100 popupPadding">
                    <p class="list-heading w-50 mb-0">Registration Date and Time </p>
                    <p class="list-heading w-50 mb-0">{{selectedUser.userRegistrationDate | date : 'M-d-YYYY h:mm a' : '--'}}</p>
                </div>
            </div>
        </div>

    </p-dialog>