import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { appConfig } from 'src/app/app.config';
import { LibraryServiceService } from 'src/app/services/library/library-service.service';
import { ArtWorkService } from 'src/app/services/artwork/artwork.service';
import { MediaService } from 'src/app/services/media/media.service';
import { AppAnimation } from 'src/app/utilities/animations/animations';
@Component({
  selector: 'app-networkseries-side-panel',
  templateUrl: './networkseries-side-panel.component.html',
  styleUrls: ['./networkseries-side-panel.component.css'],
  animations: AppAnimation,
})
export class NetworkseriesSidePanelComponent implements OnInit {
  overlay = false;
  sidePanel = false;
  openPanel = false
  subsciptionData = new Subscription();
  recentSeries: any[]=[]
  selectedNetworkData : any
  totalMediaSeries: any
  totalMediaSeriesRecords: any
  dataId : any;
  showall = false;
  listIdArray : any[]=[];
  clickFrom: any
  listId: any
  tabId: any
  itemId: any
  selectedItems: any;
  mediaItemId: any;
  networkId : any
  mobileTabId: any
  @ViewChild ('searchInput') searchInput : ElementRef
  constructor(
    public libraryService: LibraryServiceService,
    public artworkService : ArtWorkService,
    private messageService: MessageService,
    private mediaService: MediaService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getSubscriptionData()
    // this.getSubscriptionDataTvApp()
  }


  getSubscriptionData(){
    this.subsciptionData.add(
    this.libraryService.networkPanelState$.subscribe(
      (state:any) => {
  
          console.log(state.itemId)
        this.sidePanel = state.showPanel;
        this.overlay = state.showPanel
        this.dataId = state.Id
        this.mediaItemId = state.Id ? state.Id : null
        this.clickFrom = state.clickFrom
        this.itemId = state.itemId
        this.mobileTabId = state.tabId
        this.selectedItems = state.itemId ? state.itemId : null
        this.listIdArray = []
        this.listId = 0
        this.getNetworkList(1,20,'')
      }
    )
    )
  }

  // getSubscriptionDataTvApp() {
  //   this.subsciptionData.add(
  //     this.libraryService.networkPanelStateTvApp$.subscribe(
  //       (state: any) => {
  //     console.log(state)
  //         this.sidePanel = state.showPanel;
         
      
  //         this.clickFrom = state.clickFrom
        
  //         this.getNetworkList(1, 5, '')

  //       }
  //     )
  //   )
  // }

  ngOnDestroy() {
    if (this.subsciptionData) {
      this.subsciptionData.unsubscribe();
    }
  }

  ngAfterViewInit() {
   
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(debounceTime(800), distinctUntilChanged(), tap(() => {
      if(this.searchInput.nativeElement.value.length>2){
        this.getNetworkList(1,10,this.searchInput.nativeElement.value);
      }
      else if(this.searchInput.nativeElement.value.length === 0){
        this.getNetworkList(1,10,this.searchInput.nativeElement.value);
        setTimeout(() => {
          this.showall = false
        }, 1000);
      }
    })).subscribe();
  }
  
  closePanel() {
    this.sidePanel = false;
    this.overlay = false;
    this.showall = false;
    this.openPanel = false
    // this.libraryService.updateEbookPanelState({ showPanel: false })
  }

  showmore(){
    this.showall = true
    this.getNetworkList(1,this.totalMediaSeries,'');
  }


  getNetworkList(page,size,search) {
    this.mediaService.getMediaSeries(page,size,search,'NETWORK').subscribe((response: any) => {
      this.recentSeries = response.data.content
        ? response.data.content : [];
    
      this.totalMediaSeries = response.data.totalElements ? response.data.totalElements : null
      this.totalMediaSeriesRecords = response.data.totalElements ? response.data.totalElements : null
      if(response.data.totalElements < 5){
        this.showall = true
      }
    
      this.recentSeries.forEach(data =>{
        if(data.squareArtwork){
            var width = 270;var height = 270;
            let id = data.squareArtwork.document.id;
            console.log(id,`id`)
            data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;
            console.log(data['newimg'],`new`)
        }
        else if(data.bannerArtwork){
           var width = 300;var height = 100;
            let id = data.bannerArtwork.document.id;
            data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;

        }
        else if(data.wideArtwork){
            var width = 300;var height = 145;
            let id = data.wideArtwork.document.id;
            data['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width ;

        }
      })
      this.recentSeries.forEach((el) => {
          if (this.itemId === el.id) {
            // el['itemChecked'] = true
            let selectedId = 'selected-list-'+this.recentSeries.indexOf(el)
            setTimeout(() => {
              let element: HTMLElement = document.getElementById(selectedId) as HTMLElement;
              element.click();
            }, 500);
            
          }
      })
    });
  }

  apply(){
    this.changeEbookSeries()
  }
  changeEbookSeries(){
    if (this.clickFrom === 'change') {
      let filter = '';
      filter += filter += `ids=${this.mediaItemId}`
      this.mediaService.changeSeries(filter, { mediaSeriesId: this.selectedItems }).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Network updated Successfully.' });
        this.mediaService.sendUpdateToCreate({ update: true })
        this.closePanel()
        // this.radCheck = false
        this.selectedItems = [];
      }, (error) => {
        this.messageService.add({
          severity: 'error', summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.'
        });
      });
    }
    else if (this.clickFrom === 'tvApp') {

      this.mediaService.getMediaSeriesById(this.listId).subscribe((response: any) => {
        console.log('test resp =--', response)
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update successfully.' });
        this.libraryService.sendUpdateToCreate({ update: true })
        setTimeout(() => {
          this.artworkService.sendNetworkPanelId(this.selectedNetworkData)
        }, 1000);
        this.closePanel()

      }
        , (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
    }
    else{
      if(this.dataId ){
        this.tabId = this.dataId
        this.libraryService.changetabFolderdata(this.listId,this.tabId,'NETWORK').subscribe((response:any) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update successfully.' });
          this.libraryService.sendUpdateToCreate({ update: true })
          this.closePanel()
        }
      ,(error) =>{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
      
    }
  }
}

  selectItems(event,item) {
    this.selectedItems = item.id
    if(this.clickFrom === 'subtabs'){
      this.listIdArray.push(item.id)
      console.log(this.listIdArray)
    }else{
      this.listId = event.target.value
      this.selectedNetworkData = item
      // this.selectedNetworkData = item.map((el) => ({
      //   bannerArtworkId: el.bannerArtwork ? el.bannerArtwork.document.id : '',
      //   id: el.id,
      //   itemChecked: el.itemChecked,
      //   squareArtworkId: el.squareArtwork ? el.squareArtwork.document.id : '',
      //   subtitle: el.subtitle,
      //   title: el.title,
      //   wideArtworkId: el.wideArtwork ? el.wideArtwork.document.id : '',
      //   type: 'MEDIASERIES'
      // }))
      console.log(this.selectedNetworkData)
      // if(this.selectedNetworkData){
      //   this.artworkService.sendNetworkPanelId(this.selectedNetworkData)

      // }

    }
    console.log(this.listId)
    console.log(event.target)

    }

    redirectToDetailPage(data){
      if(data.status === 'DRAFT' && this.clickFrom === 'networkTab'){
        this.router.navigate(['/channel-module/myChannel/networks/' + data.id], { queryParams: { mobile: true, 'redirectId': this.mobileTabId, networkSidePanel: true } })

      }
      else if (data.status === 'DRAFT' && this.clickFrom === 'tvApp') {
        this.router.navigate(['/channel-module/myChannel/networks/' + data.id], { queryParams: { tvApp: true, 'redirectId': this.mobileTabId, networkSidePanel: true } })

      }
    }
    

  dblClickSelectNetwork(item) {
    this.apply()
  }

}
