<div class="main">
    <div class="sub-main">
        <div class="content-wrap">
            <div class="content">
                <div class="main-tv">
                    <img class="platform-image" src="assets/images/web/Tv 3.svg" (load)="onImageLoad()" priority alt="">
                    <div class="loader" *ngIf="!showData && showLoader">Data is loading...</div>
                    <div class="tv-background">
                       <div class="main-container" [hidden]="!showData" *ngIf="showVod && !showChannel && !showNetwork">
                            <section class="top-container">
                                <div class="section-wrapper">
                                    <div class="media-details">
                                        <div class="logo-wrapper">
                                            <div class="logo" style="justify-content: left;">
                                                <img [src]="logo" alt="">
                                                <!-- <img src="assets/images/web/logo.png" alt=""> -->
                                            </div>
                                        </div>
                                        <div class="description-container">
                                            <p class="title">{{showTitle}}</p>
                                            <p class="subtitle">{{showSubTitle}}</p>
                                            <p class="description">{{showDesc}}</p>
                                        </div>
                                    </div>
                                    <div class="media-banner">
                                        <div class="main-bg-image" [ngStyle]="{'background-image': 'url(' + mainHomeBgImage + ')'}"></div>
                                        <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div>
                                        <div class="main-bg-shadow left-gradiant" [style.background-image]="leftBottomGradiant"></div>
                                        <!-- <div class="main-bg-shadow" [style.background-image]="mainBGBlur"></div> -->
                                        <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div>
                                        <div class="signin-btn">
                                            <button>Sign In</button>
                                        </div>
                                        <div class="menus" style="transform: translate(0%, 0%);" *ngIf="showChannelVOD || showNetworkVOD">
                                            <li (click)="switchMenu('channel')" [ngClass]="{'activeMenu': showChannel}" *ngIf="isChannel">CHANNEL</li>
                                            <li (click)="switchMenu('network')" [ngClass]="{'activeMenu': showNetwork}" *ngIf="isNetwork">NETWORK</li>
                                            <li (click)="switchMenu('vod')" [ngClass]="{'activeMenu': showVod}" *ngIf="isVod">VOD</li>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="bottom-container">
                                <div class="media-series" *ngFor="let mediaSeries of mediaData;let seriesIndex = index">
                                    <p class="series-text" *ngIf="mediaSeries.mediaItem.length>0">{{mediaSeries.title}}</p>
                                    <!-- <div class="d-flex rowScroll">
                                        <div class="media-item-wrapper" *ngFor="let mediaItem of mediaSeries.mediaItem;let itemIndex = index">
                                            <div class="media">
                                                <div class="media-image" >
                                                    <img [src]="mediaItem.wideArtwork ? mediaItem.wideArtwork.document.newimg : ''" [ngClass]="{active: mediaItem.active === true}" (click)="activeData(seriesIndex,itemIndex)"
                                                    onload="style.opacity = 1"  style="opacity: 0;" alt="">
                                                </div>
                                                <p class="media-title">{{mediaItem.title}}</p>
                                            </div>
                                        </div>
                                    </div> -->

                                    <p-carousel *ngIf="mediaSeries.mediaItem.length>0" [value]="mediaSeries.mediaItem" [numVisible]="4" [numScroll]="4" [circular]="false">
                                        <!-- <ng-template pTemplate="header">
                                            <h5>Basic</h5>
                                        </ng-template> -->
                                        <ng-template let-product pTemplate="item">
                                            <!-- <div class="product-item">
                                                <div class="product-item-content">
                                                    {{product.title}}
                                                </div>
                                            </div> -->
                                            <div class="media-item-wrapper">
                                                <div class="media">
                                                    <div class="media-image" >
                                                        <img [src]="product.wideArtwork ? product.wideArtwork.document.newimg : ''" [ngClass]="{active: product.active === true}" (click)="activeData(seriesIndex,product.index)"
                                                        onload="style.opacity = 1"  style="opacity: 0;" alt="">
                                                    </div>
                                                    <p class="media-title">{{product.title}}</p>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-carousel>

                                </div>
                                <p class="no-data-available" *ngIf="mediaData.length===0">No data available</p>
                            </section>
                       </div>
                       <div class="main-channel" [hidden]="!showData" *ngIf="!showVod && showChannel && !showNetwork">
                        <div class="single-player">
                            <div class="menus" style="width: 720px;transform: translate(0%, 0%);" *ngIf="showChannelVOD || showNetworkVOD">
                                <li (click)="switchMenu('channel')" [ngClass]="{'activeMenu': showChannel}" *ngIf="isChannel">CHANNEL</li>
                                <li (click)="switchMenu('vod')" [ngClass]="{'activeMenu': showVod}" *ngIf="isVod">VOD</li>
                            </div>
                            <div class="video-player">
                                <app-video-player *ngIf="videoJsPlayerUrl"
                                        [options]="{muted: true,autoplay: autoPlayChange ? 'play':false, controls: true, sources: [{ src: videoJsPlayerUrl,type:videoJsPlayerType }]}">
                                </app-video-player>
                                <app-video-player *ngIf="!videoJsPlayerUrl"
                                        [options]="{muted: true,autoplay: autoPlayChange ? 'play':false, controls: true, sources: [{ src: '',type:'' }]}">
                                </app-video-player>
                            </div>
                        </div>
                       </div>
                       <div class="main-network" [hidden]="!showData" *ngIf="!showVod && !showChannel && showNetwork">
                        <div class="main-container">
                            <section class="top-container">
                                <div class="section-wrapper" style="height: 80px;">
                                    <div class="media-details">
                                        <div class="logo-wrapper">
                                            <div class="logo" style="justify-content: left; padding-top: 15px;">
                                                <img [src]="logo" style="height: 45px;" alt="">
                                                <!-- <img src="assets/images/web/logo.png" alt=""> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="media-banner">
                                        <span *ngIf="m3uType !== 'M3U_CHANNEL' && !showM3uMenu" class="categoryName">{{showCategory}}</span>
                                        <div class="signin-btn">
                                            <div *ngIf="!showM3uMenu" class="breadcrumb-icon" (click)="showM3uMenu = true;showCategory = 'All';closeNav()"><i class="fa fa-chevron-circle-left" aria-hidden="true"></i></div>
                                            <div *ngIf="m3uType !== 'M3U_CHANNEL' && !showM3uMenu" class="breadcrumb-icon" (click)="openNav()"><i class="fa fa-bars" aria-hidden="true"></i></div>
                                            <div *ngIf="m3uType !== 'M3U_CHANNEL' && !showM3uMenu" class="search-icon"><i class="fa fa-search" aria-hidden="true"></i></div>
                                            <button>Sign In</button>
                                        </div>
                                    </div>
                                    <div class="menus" *ngIf="showChannelVOD || showNetworkVOD && showM3uMenu">
                                        <li (click)="switchMenu('network')" [ngClass]="{'activeMenu': showNetwork}" *ngIf="isNetwork">NETWORK</li>
                                        <li (click)="switchMenu('vod')" [ngClass]="{'activeMenu': showVod}" *ngIf="isVod">VOD</li>
                                    </div>
                                </div>
                            </section>
                            </div>
                        <div class="network-wrapper" *ngIf="networkType === 'M3U'">
                            <!-- <div class="search">
                                <input type="text" placeholder="Search">
                            </div> -->
                            <div class="ham-options" [ngClass]="[hamOptions === true ?  'showHam' : 'hideHam']">
                                <ul class=" d-block" id="mySidenav">
                                    <!-- <li (click)="hamOptions=false" class="text-center">Close</li> -->
                                    <span class="close" (click)="closeNav()">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </span>
                                    <!-- <li *ngIf="showHideHome">{{homePageName}}</li> -->
                                    <li (click)="selectCategory('all')">All</li>
                                    <li (click)="selectCategory(item)" class="d-flex justify-content-between" *ngFor="let item of allCatagoryData">
                                        <p class="ellipsis-text mb-0">{{item.Category}}</p>
                                        <div>({{item.Count}})</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="m3u-network-wrapper position-relative" [ngClass]="{'m3u-scheduler-view': m3uType === 'M3U_CHANNEL'}">
                                <ul class="m3u-menu" *ngIf="showM3uMenu">
                                    <li (click)="selectM3uType('M3U_CHANNEL');showM3uMenu = false"><div><i class="fa fa-television" aria-hidden="true"></i></div><p>LIVE TV</p></li>
                                    <li (click)="selectM3uType('M3U_MOVIES');showM3uMenu = false"><div><i class="fa fa-film" aria-hidden="true"></i></div><p>VOD</p></li>
                                    <li (click)="selectM3uType('M3U_SERIES');showM3uMenu = false"><div><i class="fa fa-desktop" aria-hidden="true"></i></div><p>SERIES</p></li>
                                </ul>
                                <div class="main-description-player-wrapper overflow-x-wrapper" *ngIf="m3uType !== 'M3U_CHANNEL' && !showM3uMenu">
                                        <div class="col-12">
                                            <div class="m3u-data">
                                                <!-- <div class="d-flex justify-content-between align-items-center">
                                                    <h1 class="cagtagory-name">{{item.Category}}</h1>
                                                </div> -->
                                                <div class="playlistdata-wrapper">
                                                    <div class="playlistdata" *ngFor="let data of m3uData">
                                                        <div class="d-flex flex-column">
                                                            <div class="card" [ngClass]="{'selected-item': data.isSelected}" (click)="selectM3UItem(data)">
                                                                <img class="item-logo" style="opacity:0" onload="style.opacity=1" [src]="data.tvgLogo" alt="" (error)="handleMissingImage($event)" >
                                                            </div>
                                                            <!-- <p class="data-name" [title]="data.tvgName">{{data.tvgName}}</p> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div class="main-description-player-wrapper m3u-scheduler" *ngIf="m3uType === 'M3U_CHANNEL' && !showM3uMenu">
                                    <div class="container" style="padding: 0 10px">
                                        <div class="d-flex align-items-center col-12">
                                            <div class="detail-card">
                                                <div class="d-flex flex-column channel-detail-wrapper" *ngIf="currentSelectedChannelName">
                                                    <img style="opacity: 0;width:50px" onload="style.opacity = 1" [src]="currentSelectedChannelLogo ? currentSelectedChannelLogo : null" alt="">
                                                    <p class="channel-name" *ngIf="currentPlayingItemName">{{currentPlayingItemName ? currentPlayingItemName : ''}}</p>
                                                    <p class="timing" *ngIf="currentStartItemTime">{{ currentStartItemTime | date: 'hh:mm a'}} <span *ngIf="currentStartItemTime">-</span> {{currentEndItemTime | date: 'hh:mm a'}}</p>
                                                    <p class="description">{{itemDescription ? itemDescription : ''}}</p>
                                                </div>
                                            </div>
                                            <div class="">
                                                <div class="video-player-wrapper" style="width: 230px">
                                                    <ng-container>
                                                        <app-video-player 
                                                                [options]="{muted: true,autoplay: autoPlayChange ? 'play':false, controls: false, sources: [{ src: videoJsPlayerUrl,type:videoJsPlayerType }]}">
                                                        </app-video-player>
                                                        <!-- <iframe width="300" height="155" [src]="videoUrl" frameborder="0" controls="0" allowfullscreen></iframe> -->
                                                        <!-- <iframe width="560" height="315" sandbox="allow-scripts allow-same-origin" src="https://media.nowcast.cc:5443/LiveApp/play.html?name={{streamId ? streamId : ''}}" frameborder="0" allowfullscreen></iframe> -->
                                                        <span class="player-logo" *ngIf="logoOnPlayer" [ngClass]="[logoOnRight ? 'logoPositionRight' : 'logoPositionLeft']">
                                                            <img style="opacity: 0;width:50px" onload="style.opacity = 1" [src]="currentSelectedChannelLogo ? currentSelectedChannelLogo : null" alt="">
                                                        </span>
                                                        <!-- <div class="disable-div-playPause"></div>
                                                        <div class="disable-div-progress"></div> -->
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <section class="main-playlist-timeline">
                                        <div>
                                            <div class="d-flex flex-column">
                                                <div class=" d-flex time-wrapper" #scrollTwo>
                                                    <p class="day-time">{{currentDay}}, {{currentDate | date:'hh:mm a'}}</p>
                                                    <div class="d-flex all-time" *ngFor="let item of allDayDefault">
                                                        <p>{{item.start | date: 'hh:mm a'}}</p>
                                                        
                                                    </div>
                                                    <div class="ruler-wrapper" #scrollthree>
                                                        <span class="ruler-horizontal" [style.backgroundColor]="'#fff'" [ngStyle]="{'width': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.13888889) + 200) + 'px') : '0'}"></span>
                                                        <span class="triangle-down" [style.borderTop]="'19px solid #fff'" [title]="currentDate | date:'hh:mm:ss a'" [ngStyle]="{'left': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.13888889) + 185) + 'px') : '0'}"></span>
                                                        
                                                    </div>
                                                </div>
                                                <div class="playlist-wrapper" (scroll)="updateScroll()" #scrollOne>
                                                    <div class="d-flex">
                                                        <div>
                                                            <div *ngFor="let item of channelData" >
                                                                <div class="channel-wrapper d-flex" *ngFor="let data of item.Playlist" (click)="selectChannel(data)" [ngClass]="{'activeChannel': data.isSelected}" >
                                                                    <p class="d-flex justify-content-between">
                                                                        <!-- <span style="width: 40%;padding-left: 10px;" class="d-flex">
                                                                            {{data.channelNumber}}
                                                                        </span> -->
                                                                        <span class="d-flex justify-content-center" style="width: 45%;">
                                                                            <img [src]="data.tvgLogo"
                                                                                style="width:auto;height:30px;margin-right:15px;object-fit: contain;max-width: 45px;" alt="">
                                                                        </span>
                                                                        <span [tooltip]="data.channelName" class="d-flex  align-items-center itemName" style="font-size: 12px;height:35px;">
                                                                            {{data.channelName}}
                                                                        </span>
                                                                    </p>
                                                                    <div class="all-playlist-wrapper d-flex" *ngFor="let item of data.playlist">
                                                                        <div class="playlist-data" [ngStyle]="{'width': item.duration ? ((item.duration * 0.13888889) + 'px') : '0'}" [title]="item.tvgName"><span>{{item.tvgName}}</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="no-data" *ngIf="channelData.length === 0">No data exist</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="custom-network" *ngIf="networkType === 'CUSTOM'">
                            <section class="main-description-player-wrapper">
                                <div class="container" style="padding: 0 10px">
                                    <div class="d-flex align-items-center col-12">
                                        <div class="detail-card">
                                            <div class="d-flex flex-column channel-detail-wrapper" *ngIf="currentSelectedChannelName">
                                                <img style="opacity: 0;width:50px" onload="style.opacity = 1" [src]="currentSelectedChannelLogo ? currentSelectedChannelLogo : null" alt="">
                                                <p class="channel-name" *ngIf="currentPlayingItemName">{{currentPlayingItemName}}</p>
                                                <p class="timing" *ngIf="currentStartItemTime">{{ currentStartItemTime | date: 'hh:mm a'}} <span *ngIf="currentStartItemTime">-</span> {{currentEndItemTime | date: 'hh:mm a'}}</p>
                                                <p class="description">{{itemDescription ? itemDescription : ''}}</p>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="video-player-wrapper" style="width: 230px">
                                                <ng-container>
                                                    <app-video-player 
                                                            [options]="{muted: true,autoplay: autoPlayChange ? 'play':false, controls: false, sources: [{ src: videoJsPlayerUrl,type:videoJsPlayerType }]}">
                                                    </app-video-player>
                                                    <!-- <iframe width="300" height="155" [src]="videoUrl" frameborder="0" controls="0" allowfullscreen></iframe> -->
                                                    <!-- <iframe width="560" height="315" sandbox="allow-scripts allow-same-origin" src="https://media.nowcast.cc:5443/LiveApp/play.html?name={{streamId ? streamId : ''}}" frameborder="0" allowfullscreen></iframe> -->
                                                    <span class="player-logo" *ngIf="logoOnPlayer" [ngClass]="[logoOnRight ? 'logoPositionRight' : 'logoPositionLeft']">
                                                        <img style="opacity: 0;width:50px" onload="style.opacity = 1" [src]="currentSelectedChannelLogo ? currentSelectedChannelLogo : null" alt="">
                                                    </span>
                                                    <div class="disable-div-playPause"></div>
                                                    <div class="disable-div-progress"></div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <!-- <div class="video-player">
                                <app-video-player
                                        [options]="{muted: true,autoplay: autoPlayChange ? 'play':false, controls: false, sources: [{ src: videoJsPlayerUrl,type:videoJsPlayerType }]}">
                                </app-video-player>
                            </div>
                            <div class="logo" style="width: 55px;padding-left: 10px;padding-bottom: 5px;padding-top: 10px;">
                                <img style="opacity: 0;width: 100%;" onload="style.opacity = 1" [src]="currentSelectedChannelLogo ? currentSelectedChannelLogo : null" alt="">
                            </div>
                            <p class="channel-name" *ngIf="currentPlayingItemName">{{currentPlayingItemName}}</p> -->
                            <section class="main-catagory">
                                <div class="catagory-wrapper">
                                    <ul>
                                        <li  *ngFor="let item of categoryData">{{item.categoryName}}</li>
                                    </ul>
                                </div>
                            </section>
                            <section class="main-playlist-timeline">
                                <div>
                                    <div class="d-flex flex-column">
                                        <div class=" d-flex time-wrapper" #scrollTwo>
                                            <p class="day-time">{{currentDay}}, {{currentDate | date:'hh:mm a'}}</p>
                                            <div class="d-flex all-time" *ngFor="let item of allDayDefault">
                                                <p>{{item.start | date: 'hh:mm a'}}</p>
                                                
                                            </div>
                                            <div class="ruler-wrapper" #scrollthree>
                                                <span class="ruler-horizontal" [style.backgroundColor]="'#fff'" [ngStyle]="{'width': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.13888889) + 225) + 'px') : '0'}"></span>
                                                <span class="triangle-down" [style.borderTop]="'19px solid #fff'" [title]="currentDate | date:'hh:mm:ss a'" [ngStyle]="{'left': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.13888889) + 210) + 'px') : '0'}"></span>
                                                
                                            </div>
                                        </div>
                                        <div class="playlist-wrapper" (scroll)="updateScroll()" #scrollOne>
                                            <div class="d-flex">
                                                <div>
                                                    <div *ngFor="let item of customNetworkData" >
                                                        <div class="channel-wrapper d-flex" *ngFor="let data of item.Playlist" (click)="selectChannel(data)" [ngClass]="{'activeChannel': data.isSelected}" >
                                                            <p class="d-flex">
                                                                <span style="width: 45px" class="d-flex">
                                                                    {{data.channelNumber}}
                                                                </span>
                                                                <span class="d-flex justify-content-center" style="width: 65px;margin: 0 5px">
                                                                    <img [src]="data.logoPath"
                                                                        style="width:auto;height:30px;object-fit: contain;max-width: 45px;" alt="">
                                                                </span>
                                                                <span class="d-flex justify-content-center" style="font-size: 12px;width:100px;height:35px;">
                                                                    {{data.title}}
                                                                </span>
                                                            </p>
                                                            <div class="all-playlist-wrapper d-flex" *ngFor="let item of data.schedulingChannelInfoDTO">
                                                                <div class="playlist-data" [ngStyle]="{'width': item.duration ? ((item.duration * 0.13888889) + 'px') : '0'}" [title]="item.name"><span>{{item.name}}</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="no-data" *ngIf="customNetworkData.length === 0">No data exist</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </section>
                        </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>