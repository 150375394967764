import { MessageService } from 'primeng/api';
import { SharedService } from './../../../services/shared/shared.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HeaderService } from './../../../services/shared/header.service';
import { Subscription } from 'rxjs';
import { appConfig } from 'src/app/app.config';
import { ProfileServicesService } from 'src/app/services/profile/profile-services.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() panelState;
  @Output() sidenav = new EventEmitter<string>();
  @Output() smallSidenav = new EventEmitter<string>();
  currentRoute: string;
  userName: string;
  subscriptionData = new Subscription();
  profileImg = 'assets/images/header/avatar.png'
  logoId: string
  showUpdatePopup: boolean;
  subscriptonProgressData = new Subscription();
  stopInterval: any;
  progressInfos: any;
  isPaymentDone: any
  activeCard: any;
  subscriptonData2 = new Subscription();
  userRoles: any[] = [];
  removeAnimation = false
  startInterval: any;
  showCurrentRoute = false
  constructor(
    public sharedService: SharedService,
    public route: ActivatedRoute,
    public router: Router,
    public headerService: HeaderService,
    public messageService: MessageService,
    public profileService: ProfileServicesService
  ) { }

  ngOnInit(): void {
    this.getHeaderName();
    this.getSubsciptionData();
    this.getSubsciptionData2()
    this.getBroadcastedData();
    this.userName = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.firstName : '';
    this.logoId = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.logoId : null;
    if (this.logoId) {
      this.profileImg = `${appConfig.imageURL}` + this.logoId;
    } else {
      this.profileImg = 'assets/images/header/avatar.png'
    }
  }

  ngOnDestroy() {
    if (this.subscriptionData) {
      this.subscriptionData.unsubscribe();
    }
    if (this.subscriptonData2) {
      this.subscriptonData2.unsubscribe();
    }
    if (this.subscriptonProgressData) {
      this.subscriptonProgressData.unsubscribe();
    }
  }

  getSubsciptionData2() {
    this.subscriptonData2.add(
      this.sharedService.updateUserRole$.subscribe((role: any) => {
        this.userRoles = role;
      })
    )
  }

  getHeaderName() {
    let pathName = window.location.pathname.slice(1).split('/');
    this.currentRoute = (this.route.firstChild && this.route.firstChild.snapshot.children[0] && pathName.length <= 2) ?
      this.route.firstChild.snapshot.children[0].data.header :
      ((this.route.firstChild && this.route.firstChild.snapshot.children[0] && pathName.length >= 3) ?
        this.getChildRouteName(this.route.firstChild.snapshot.children[0].routeConfig.children, pathName[pathName.length - 1], this.currentRoute) :
        this.route.firstChild.snapshot.data.header);
        console.log('current route --' , this.currentRoute)
    this.router.events.subscribe((routeObj) => {
      if (routeObj instanceof NavigationEnd) {
        if (routeObj.url.indexOf('customer-module') < 0) {
          localStorage.removeItem('userList');
        }
        pathName = routeObj.url.slice(1).split('/');
        this.currentRoute = (this.route.firstChild && this.route.firstChild.snapshot.children[0] && pathName.length <= 2) ?
          this.route.firstChild.snapshot.children[0].data.header :
          ((this.route.firstChild && this.route.firstChild.snapshot.children[0] && pathName.length >= 3) ?
            this.getChildRouteName(this.route.firstChild.snapshot.children[0].routeConfig.children, pathName[pathName.length - 1], this.currentRoute) :
            this.route.firstChild.snapshot.data.header);
        if (this.router.url.includes('media-module/media/live-streaming/edit-live-stream')) {
          this.currentRoute = "Live Streaming"
        }
        setTimeout(() => {
          const loggedInUserData = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : [];
          if (!Array.isArray(loggedInUserData)) {
            const userRoles = loggedInUserData.userDetails.roles.map((el) => el.name);
            this.isPaymentDone = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.isPaymentDone : null;
            this.activeCard = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.activeCard : null;
            this.sharedService.updateUserRoleData(userRoles);
          }
        }, 0);
      }
    });
    setTimeout(() => {
      const loggedInUserData = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : [];
      if (!Array.isArray(loggedInUserData)) {
        const userRoles = loggedInUserData.userDetails.roles.map((el) => el.name);
        this.isPaymentDone = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.isPaymentDone : null;
            this.activeCard = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.activeCard : null;
        this.sharedService.updateUserRoleData(userRoles);
      }
    }, 0);
    if (this.router.url.includes('media-module/media/live-streaming/edit-live-stream')) {
      this.currentRoute = "Live Streaming"
    }
    // setTimeout(() => {
    //   this.showCurrentRoute = true
    // }, 500);
    if (this.router.url.includes('subscription/edit-custom-plan')) {
      this.currentRoute = "Subscription"
    }
  }

  /**
   * To get broadcasted data
   */
  getBroadcastedData() {
    this.subscriptionData.add(
      this.sharedService.switchUserData$.subscribe((response: any) => {
        this.userName = response.userDetails.firstName;
        this.logoId = response.userDetails.logoId
        if (this.logoId) {
          this.profileImg = `${appConfig.imageURL}` + this.logoId;
        }
        else {
          this.profileImg = 'assets/images/header/avatar.png'
        }
      })
    );
    this.subscriptionData.add(
      this.profileService.profileImage$.subscribe((response: any) => {
        response.forEach((el, i) => {
          if (i === 1) {
            this.userName = el
          }
          else if (i !== 1 && el !== null) {
            this.logoId = el;
            this.profileImg = `${appConfig.imageURL}` + this.logoId;
          }
          else if (i !== null && el === null) {
            this.profileImg = 'assets/images/header/avatar.png'
          }
        })
        // this.logoId = response;
        // this.profileImg = `${appConfig.imageURL}` + this.logoId;
        // this.userName = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).userDetails.firstName : '';
      })
    );
  }

  openSidebar() {
    this.sharedService.updateSidebarState(true);
  }

  getChildRouteName(routeChilds, currentRoute, parentRoute) {
    let headerName = '';
    const formattedRouteName = currentRoute.split('?')[0];
    const matchedRoute = routeChilds ? routeChilds.find((el) => el.path === formattedRouteName) : '';
    headerName = matchedRoute ? matchedRoute.data.header : routeChilds ? routeChilds[routeChilds.length - 1].data.header : '';
    headerName = headerName ? headerName : parentRoute;
    return headerName;
  }

  logOut() {
    this.headerService.logOutUser().subscribe((response: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Logged out successfully.' });
      setTimeout(() => {
        localStorage.clear();
        this.router.navigate(['/login']);
      }, 100);
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
    });
  }

  toggle() {
    if (window.outerWidth > 529) {
      this.smallSidenav.emit();
    } else {
      this.sidenav.emit();
    }
  }

  getSubsciptionData() {
    this.getProgressDataData()
  }

  updateProgressValue(index, progressInfos) {
    // for (let i = 0; i < progressInfos.length; i++) {
    //   progressInfos = localStorage.getItem('progress') ? JSON.parse(localStorage.getItem('progress')) : ''
    //   if(progressInfos[i].value === 0){
    //       clearInterval(this.stopInterval);
    //       // this.progress = 0
    //       // this.getMediaItemData(this.mediaItemId);
    //     }
    // }
    let newData = localStorage.getItem('progress') ? JSON.parse(localStorage.getItem('progress')) : ''
    if (newData.length === 0) {
      clearInterval(this.stopInterval);
    }
    this.progressInfos[index].value = newData[index].value
    if (this.progressInfos[index].value === 0) {
      this.progressInfos.splice(index, 1);
      localStorage.setItem("progress", JSON.stringify(this.progressInfos));
      var checkData = localStorage.getItem('progress') ? JSON.parse(localStorage.getItem('progress')) : ''
      if (checkData.length === 0) {
        this.showUpdatePopup = false;
      }
    }
    // setInterval(
    // console.log('in invetrval');
    // this.videoName = localStorage.getItem('itemName').toString()
    // if(parseInt(progressCount) === 0){
    //   clearInterval(this.stopInterval);
    //   this.progress = 0
    //   // this.getMediaItemData(this.mediaItemId);
    // }
    // // ,500)
    // console.log(progressCount);
    // if(parseInt(progressCount)){
    //   this.progress = parseInt(progressCount);
    //   // console.log(this.progress);
    // }
  }


  /**
   * To get subscription data
   */
  getProgressDataData() {
    this.subscriptonProgressData.add(
      this.sharedService.updateProgressBar$.subscribe((data: any) => {
        this.progressInfos = data;
        console.log(this.progressInfos,'<----------this.progressInfos');
        
        if (this.userRoles.indexOf('ROLE_SUPER_ADMIN') > -1 || this.userRoles.indexOf('ROLE_ON_BOARDING') > -1) {
          this.showUpdatePopup = false
        }
        else {
          // console.log(this.progressInfos.length, '<---------this.progressInfos.length');
          if(this.progressInfos.length>0){
            clearInterval(this.startInterval)
            this.startInterval = setInterval(() => {
              const checkStatus = (value) => value.status === 'Done';
                  let check = this.progressInfos.every(checkStatus)
                  if(check === true){
                    this.showUpdatePopup = false
                    clearInterval(this.startInterval)
                    this.progressInfos = []
                  }
                  else{
                    clearInterval(this.startInterval)
                  }
                }, 500);
          }
          if (this.progressInfos.length === 0) {
            this.showUpdatePopup = false
          }
          this.progressInfos.forEach((el, index) => {
            if (el.value > 0) {
              this.showUpdatePopup = true;
            }
          })
        }

      })
    );
  }


}
