import { SharedService } from './../services/shared/shared.service';

import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { TimezoneService } from '../services/shared/timezone.service';
import { DOCUMENT } from '@angular/common';
import { MessageService } from 'primeng/api';
import { FeatureControlService } from '../services/feature-control.service';

@Injectable()

export class AppInterceptor implements HttpInterceptor {
    totalRequest = 0;
    sendTimeZone: any = new Date();
    currentZone: any
    constructor(
        public router: Router,
        public sharedService: SharedService,
        public featureControlService: FeatureControlService,
        public timezoneService: TimezoneService,
        public messageService: MessageService,
        @Inject(DOCUMENT) private document: Document
    ) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.totalRequest = 0;
                this.timezoneService.getTimezoneChanges().subscribe((timezone) => {
                    this.currentZone = timezone;
                    let defaultTimeZone = localStorage.getItem('timeZone') ? localStorage.getItem('timeZone') : ''
                    if (defaultTimeZone) {
                        if (defaultTimeZone !== this.currentZone) {
                            this.sharedService.updateLoader(true);
                            this.messageService.add({ severity: 'error', summary: '', sticky: true, life: 3500, detail: 'Timezone updated. Application is reloading.' });
                            setTimeout(() => {
                                this.sharedService.updateLoader(true);
                                window.location.reload();
                            }, 3500);
                            this.sharedService.updateLoader(true);
                        }
                    }
                });
            }
        });
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.totalRequest++;
        this.sharedService.updateLoader(true);
        this.featureControlService.getFeatureList()
        let defaultTimeZone = localStorage.getItem('timeZone') ? localStorage.getItem('timeZone') : ''
        // this.sendTimeZone = defaultTimeZone
        const authToken = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')).token : '';

        const requestToHandle = authToken && !request.url.includes('users/userLogin')
            ? request.clone({
                headers: request.headers.set('Authorization', `Bearer ${authToken}`),
                setHeaders: { deviceType: 'WEB', TimeZone: defaultTimeZone },
            })
            : request;
        return next.handle(requestToHandle).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (event.status && (event.status === 200 || 201)) {
                    if (this.totalRequest > 0) {
                        this.totalRequest--;
                    }
                    if (this.totalRequest === 0) {
                        this.sharedService.updateLoader(false);
                    }
                }
            }
        }, (error) => {
            this.totalRequest--;
            this.sharedService.updateLoader(false);
            if (error.status === 401) {
                localStorage.clear();
                this.router.navigate(['/login']);
            } else {
                return throwError(error);
            }
        }));
    }
}


