<div class="app-block mt-4">

    <p class="info-title">
        Storage
    </p>
    <span class="para-text">
        {{usedUsageStorageMemory}} / {{totalStorageMemory}}
        used ({{percentageUsed}}%)

    </span>
        <!-- {{totalUsedMemory}} GB /
        {{totalDiskSpace === 536870912000
        ? totalDiskSpaceGB + ' GB' : totalDiskSpaceTB + ' TB'}}
        used ({{totalUsedMemoryPercent}}%) -->
    <div class="total-storage d-flex">
        <div class="current-video-storage" [style.width]="totalVideoPercent+'%'">

        </div>

        <div class="current-audio-storage" [style.width]="totalAudioPercent+'%'">

        </div>
        <div class="current-image-storage" [style.width]="totalImagePercent+'%'">

        </div>

        <div class="current-document-storage" [style.width]="totalDocumentPercent+'%'">

        </div>

    </div>

    <div class="d-flex align-items-center line-height1">
        <div class="video-span">

        </div>
        <span class="para-text">
            Video - {{videoMemory ? videoMemory : '0 GB'}}
        </span>


        <div class="audio-span">

        </div>
        <span class="para-text">
            Audio - {{audioMemory ? audioMemory : '0 GB'}}
        </span>
        <div class="image-span">

        </div>
        <span class="para-text">
            Image - {{imageMemory ? imageMemory : '0 GB'}}
        </span>
        <div class="document-span">

        </div>
        <span class="para-text">
            Document - {{documentMemory ? documentMemory : '0 GB'}}
        </span>

    </div>

</div>
<div class="app-block mt-4">

    <div class="info-title d-flex align-items-center justify-content-between">
        <p>VOD Data Usage</p>
        <div class="d-flex align-items-center justify-content-between">
            <div class="currentMonth" (click)="SelectCurrent()" [ngClass]="{'activeBtn': selectAllType}">Current
                Month</div>
            <!-- <p class="info-title" style="cursor: pointer;font-size: 14px;" (click)="unSelectAll()"><b>{{monthName |
                uppercase}}</b></p> -->
            <div class="calnderbtn">
                <p-calendar name="month" [(ngModel)]="dateData" placeholder="Select Month"
                    (onSelect)="changeMonth($event)" class="monthDate" view="month" dateFormat="mm/yy"
                    [yearNavigator]="true" yearRange="2000:2050" [readonlyInput]="true" inputId="monthpicker">
                </p-calendar>
            </div>
        </div>
        <!-- <span class="app-dropdown">
            <select class="form-control date-dropdown" (change)="dateFunction($event)" placeholder="Date"
                #dateInput [(ngModel)]="selectedFilter">
                <option value="month" selected="selected">Current Month</option>
                <option value="range">Date Range</option>
            </select>
        </span> -->
    </div>
    <span class="para-text">
            {{usedUsageMemory}} / {{totalMemory}}
            used ({{totalUsedUsagePercent}}%)
    </span>
    <div class="total-storage d-flex">
        <div class="current-video-storage" [style.width]="totalUsedUsagePercent +'%'"></div>
        <!-- <span class="hoverEffect">
            <div class="video-span"></div>
            <span>Data Usage</span>
        </span> -->
    </div>

    <div class="d-flex align-items-center">
        <div class="video-span">

        </div>
        <span class="para-text">
            Data Usage - {{usedUsageMemory ? usedUsageMemory : 0}}
        </span>
    </div>

</div>

<div class="app-block mt-4" *ngIf="isLiveSectionShow">
    <div class="info-title d-flex align-items-center justify-content-between">
        <p>Live Stream Usage</p>
        <div class="d-flex align-items-center justify-content-between">
            <div class="currentMonth" (click)="selectLiveStreamCurrentDate()" [ngClass]="{'activeBtn': liveSelectAllType}">Current
                Month</div>
            <div class="calnderbtn">
                <p-calendar name="month" [(ngModel)]="liveDateData" placeholder="Select Month"
                    (onSelect)="changeMonthLiveStream($event)" class="monthDate" view="month" dateFormat="mm/yy"
                    [yearNavigator]="true" yearRange="2000:2050" [readonlyInput]="true" inputId="monthpicker">
                </p-calendar>
            </div>
        </div>
    </div>
    <span class="para-text">
            {{liveStreamUsageMemory}} / {{liveStreamTotalMemory}}
            used ({{liveStreamTotalUsedUsagePercent}}%)
    </span>
    <div class="total-storage d-flex">
        <div class="current-video-storage" [style.width]="liveStreamTotalUsedUsagePercent +'%'"></div>
    </div>
    <div class="d-flex align-items-center">
        <div class="video-span">
        </div>
        <span class="para-text">
            Data Usage - {{liveStreamUsageMemory ? liveStreamUsageMemory : 0}}
        </span>
    </div>
</div>