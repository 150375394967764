import { appConfig } from './../../app.config';
import { Injectable } from '@angular/core';
import { ResourceService } from '../resource/resource.service';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ArtWorkService {

  constructor(public resourceService: ResourceService) { }

  sendNetworkPanelIds = new Subject();
  sendNetworkPanelIds$ = this.sendNetworkPanelIds.asObservable();

  sendChannelPanelIds = new Subject();
  sendChannelPanelIds$ = this.sendChannelPanelIds.asObservable();

  sendNetworkPanelId(data) {
    this.sendNetworkPanelIds.next(data)
  }

  sendChannelPanelId(data) {
    this.sendChannelPanelIds.next(data)
  }

  getStockList(organizationId, type) {
    let getStockUrl;
    if (type === 'LIBERARY') {
      getStockUrl = `${appConfig.updateStock}?organixationId=${organizationId}&stockType=${type}`;
    } else {
      getStockUrl = `${appConfig.updateStock}?stockType=${type}`;
    }
    return this.resourceService.getData(getStockUrl);
  }

  getStockListInOrganization(artworkId,artworkType,organizationId,page,size, type) {
    let getStockUrl;
    // const filter = ( artworkId.length === undefined) ? '' : `artworkId=${artworkId}&`
    // const filter = ( Object.keys(artworkId).length === 0 && artworkId.constructor === Object) ? '' : `artworkID=${artworkId}&`

    // const filter = (Object.keys(artworkId).length === 0 && artworkId.constructor === Object) ? '' : (Object.keys(artworkId).length === 0 ? `artworkIdList=${artworkId}&` : ( Object.keys(artworkId).length === 1 ? `artworkIdList=${artworkId}&artworkIdList=${artworkId}&` : ( Object.keys(artworkId).length === 2 ? `artworkIdList=${artworkId}&artworkIdList=${artworkId}&artworkIdList=${artworkId}` : ( Object.keys(artworkId).length === 3 ? `artworkIdList=${artworkId}&artworkIdList=${artworkId}&artworkIdList=${artworkId}&artworkIdList=${artworkId}` : `artworkIdList=${artworkId}&artworkIdList=${artworkId}&artworkIdList=${artworkId}&artworkIdList=${artworkId}&artworkIdList=${artworkId}` ) ) ) )

    artworkType = artworkType === 'square' ? `SQUARE` : (artworkType === `wide` ? 'WIDE' :(artworkType === `EBOOK`? 'EBOOK':(artworkType === `banner`? 'BANNER':(artworkType === `LAUNCH_SCREEN` ? 'LAUNCH_SCREEN': (artworkType === `APP_ICON` ? 'APP_ICON':'LOGO')))))

    // if (type === 'LIBERARY') {
    //   // getStockUrl = `${appConfig.updateStock}?organixationId=${organizationId}&stockType=${type}`;

    //   getStockUrl = `${appConfig.updateStock}?${filter}artworkType=${artworkType}&organixationId=${organizationId}&page=${page}&size=${size}&stockType=${type}`

    // } else {
    //   getStockUrl = `${appConfig.updateStock}?${filter}artworkType=${artworkType}&page=${page}&size=${size}&stockType=${type}`;
    // }

    if (type === 'LIBERARY') {
      // getStockUrl = `${appConfig.updateStock}?organixationId=${organizationId}&stockType=${type}`;

      getStockUrl = `${appConfig.updateStock}?${artworkId}artworkType=${artworkType}&organixationId=${organizationId}&page=${page}&size=${size}&stockType=${type}`

    } else {
      getStockUrl = `${appConfig.updateStock}?${artworkId}artworkType=${artworkType}&page=${page}&size=${size}&stockType=${type}`;
    }

    return this.resourceService.getData(getStockUrl);
  }


  uploadStock(stockData) {
    const uploadStockUrl = appConfig.updateStock;
    return this.resourceService.postData(uploadStockUrl, stockData);
  }

  uploadBase64(stockData) {
    const uploadBaseUrl = `${appConfig.upload}/base64images`;
    return this.resourceService.postData(uploadBaseUrl, stockData);
  }

  uploadBase64Img(formData) {
    const uploadFileUrl = `${appConfig.upload}/base64images`;
    return this.resourceService.postNewData(uploadFileUrl, formData);
  }

  getImages(imageId,width,height) {
    const getImagesUrl = `${appConfig.upload}/${imageId}?height=${height}&width=${width}`;
    return this.resourceService.getData(getImagesUrl);
  }

  deleteStock(stockId) {
    const deleteStockUrl = `${appConfig.updateStock}/${stockId}`;
    return this.resourceService.deleteData(deleteStockUrl);
  }

  getChildStock(stockId) {
    const getChildStockUrl = `${appConfig.updateStock}/parent/${stockId}`;
    return this.resourceService.getData(getChildStockUrl);
  }
}

