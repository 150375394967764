<div class="dashboard-page-wrapper mx-2">
    <ng-container *ngIf="userRoles.indexOf('ROLE_SUPER_ADMIN') > -1">
        <div class="app-filters">
            <!-- <span class="app-dropdown mr-10">
                <select class="form-control">
                    <option>Select Customer</option>
                </select>
            </span> -->
            <span class="app-dropdown mr-10">
                <select class="form-control" [(ngModel)]="year" (change)="getFilteredData()">
                    <option value="">Year</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
            </span>
            <span class="app-dropdown">
                <select class="form-control" [(ngModel)]="month" (change)="getFilteredData()">
                    <option value="">Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>
            </span>
        </div>
        <div class="row mt-20">
            <div *ngIf="expense" class="col-lg-6 col-md-12 col-12">
                <div class="dashboard-item pr-0">
                    <p class="dashboard-title superadmin-title f-20"><strong>Expenses</strong></p>
                    <!-- <ul class="revenue-list m-0 revenue-list-li">
                        <li *ngFor="let expense of expenseList">
                            <span class="mb-1">{{expense.name | titlecase}}</span>
                            <span class="mb-1">{{expense.amount | currency}}</span>
                        </li>
                    </ul> -->
                    <ul class="revenue-list m-0">
                        <li class="total-row" ><span>Total</span><span class="mr-20">{{totalExpense | currency}}</span></li>
                    </ul>
                </div>
            </div>
            <div *ngIf="revenue"  class="col-lg-6 col-md-12 col-12 mt-md-10">
                <div class="dashboard-item pr-0">
                    <p class="dashboard-title superadmin-title f-20"><strong>Revenue</strong></p>
                    <div class="revenue-scroll">

<ul class="revenue-list-items m-0" [ngClass]="{'fixed': revenueList.length > 3}">
    <li *ngFor="let revenue of revenueList" style=" margin-bottom:2px">
        <span *ngIf="revenue['subscriptionPlan'].name !== 'Others' " style=" margin-bottom:2px"
            class="mb-1">{{ revenue['subscriptionPlan'].name }}</span>
        <span *ngIf="revenue['subscriptionPlan'].name !== 'Others' " class="mb-1 mr-20">{{revenue['totalAmount'] |
            currency}}</span>
    </li>

    <li style=" margin-bottom:2px">
        <span class="mb-1">Others</span>
        <span class="mb-1 mr-20">{{totalOthers | currency}}</span>
    </li>

</ul>
                    </div>

                    <ul class="revenue-list m-0" style="padding-top: 15px !important;">
                        <li class="total-row"><span>Total</span><span class="mr-20">{{totalRevenue | currency}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
        <div  class="row mt-20 mt-sm-10">
            <div class="col-12">
                <div class="dashboard-item">
                    <div class="row align-center-md">
                        <p class="dashboard-title superadmin-title f-20 visible-md"><strong>Summary</strong></p>
                        <div class="col-lg-6 col-md-12 col-12">
                            <p class="dashboard-title superadmin-title f-20 hidden-md"><strong>Summary</strong></p>
                            <ul class="revenue-list summary-list">
                                <li *ngIf="expense">
                                    <p><em></em>Expenses </p>
                                    <span>{{totalExpense | currency}}</span>
                                </li>
                                <li  *ngIf="revenue">
                                    <p><em class="revenue-color"></em>Revenue </p>
                                    <span>{{totalRevenue | currency}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-12 col-12 py-3">
                            <p-chart type="doughnut" [data]="summaryData" [options]="summaryChartOptions"></p-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container
        *ngIf="userRoles.indexOf('ROLE_ORGANIZATION_ADMIN') > -1 || userRoles.indexOf('ROLE_USER') > -1 || userRoles.indexOf('ROLE_ADMIN') > -1 || userRoles.indexOf('ROLE_BILLING_MANAGER') > -1 || userRoles.indexOf('ROLE_CONTENT_MANAGER') > -1 ">


        <!-- new dashboard start -->
        <div class="row mb-20" *ngIf="showPromotion" >
            <div class="col-12">
                <div class="dashboard-item text-center" >
                    <div class="promotion-img-wrapper"  style="width: 100%; height: 100%;min-height: 320px;border-radius: 8px;" [ngStyle]="{'background-color': promotionaImageBgColor ? promotionaImageBgColor : '#e6e6e6'}">
                        <a (click)="openPromoLink(promotionalLink)"><img [ngClass]="promotionalLink ? 'pointer' : 'noPointer' " style="width: 100%;"  onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0; width: 100%;border-radius: 8px;" [src]="promotionImage" alt=""></a>
                        <img class="cross-icon-img" (click)="deleteImage(documentId)"
                src="./../../../../assets/images/library/close.svg" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 main-dashboard">
                <div class="card mb-20" *ngIf="featureControlService.showAnalytics">
                    <div class="d-flex justify-content-between align-items-center" [ngClass]="{'flex-column': isMobileandTabView}">
                        <h3 class="card-heading mb-0">App Downloads</h3>
                        <ul class="d-flex button-wrapper" style="margin-bottom: 0;">

                        <li (click)="activegraph('all')" class="buttons"
                           *ngIf="((mobileAppAndroid || mobileAppIos) && (tvAppAndroidTV || tvAppIOSTV || tvAppRoku))"
                            [ngClass]="[showAllbarGraph ? 'activeBtn': '']">Mobile & TV



                            </li>
                            <!-- *ngIf="((featureControlService.showTvApp && featureControlService.showMobileApp && (mobileAppAndroid || mobileAppIos))
                            )" -->
                            <li (click)="activegraph('tv')"  class="buttons" *ngIf="tvAppAndroidTV || tvAppIOSTV || tvAppRoku"
                             [ngClass]="[showTvGraph ? 'activeBtn': '']" >TV</li>
                            <li (click)="activegraph('mobile')" class="buttons" *ngIf="mobileAppAndroid || mobileAppIos"
                            [ngClass]="[showMobileGraph ? 'activeBtn': '']" >Mobile</li>
                        </ul>
                    </div>
                    <!-- <p-chart type="bar" [data]="basicData" [options]="basicOptions"></p-chart> -->
                    <!-- <canvas baseChart
                    *ngIf="showAllbarGraph" width="99%" height="40vh" #myBarCanvas
                            [datasets]="barChartData"
                            [labels]="barChartLabels"
                            [options]="barChartOptions"
                            [colors]="barChartColors"
                            [legend]="barChartLegend"
                            [chartType]="barChartType"
                            [plugins]="barChartPlugins">
                        </canvas> -->
                     <p-chart type="bar" #myBarCanvas2 *ngIf="showAllbarGraph" width="99%" height="40vh" [responsive]="true"
                                                [data]="AllappDownloadsData" [options]="stackedOptions"></p-chart>

                    <!-- <p-chart type="bar" #myBarCanvas2  width="99%" height="40vh"
                     [responsive]="true" [data]="AllappDownloadsData" [options]="stackedOptions"
                     *ngIf="showAllbarGraph && ((mobileAppAndroid || mobileAppIos) && (tvAppAndroidTV ||
                     tvAppIOSTV || tvAppRoku))"></p-chart> -->
                    <p-chart type="bar" #myBarCanvas2 *ngIf="showTvGraph" width="99%" height="40vh" [responsive]="true" [data]="onlytvappDownloadsData" [options]="stackedOptions"></p-chart>
                    <p-chart type="bar" #myBarCanvas2 *ngIf="showMobileGraph" width="99%" height="40vh" [responsive]="true" [data]="onlymobileappDownloadsData" [options]="stackedOptions"></p-chart>
                    <!-- <p-chart type="line" [data]="multiAxisData" [options]="multiAxisOptions"></p-chart> -->
                    <div>
                        <a (click)="routeToAnalytic('appDownloads')" class="routeLink">See all ></a>
                    </div>
                </div>

               <div class="cards-wrapper d-flex justify-content-between mb-20">
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="card small-cards">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="info">
                                <div class="d-flex flex-column">
                                    <h3 class="card-heading">In Trial Subscribers</h3>
                                    <h3 class="animated-num" style="font-size: 35px;color:#707070;margin:0;margin-bottom: 15px;">{{totalTrial}}</h3>
                                    <a (click)="route('inTrialSubs')" class="routeLink">See all ></a>
                                </div>
                            </div>
                            <div class="chart">
                                <!-- <p-chart type="line" width="99%" height="10vh" [responsive]="true" [data]="basicData2" [options]="basicOptions2"></p-chart> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="card small-cards">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="info">
                                <div class="d-flex flex-column">
                                    <h3 class="card-heading">Paid Subscribers
                                        <!-- <span class="indicator brown" style="margin-left: 5px;font-size: 13px;">60.23%</span> -->
                                    </h3>
                                    <h3 style="font-size: 35px;margin:0;color:#f5803e;margin-bottom: 15px;">{{activePaidSubscribers}}</h3>
                                    <a (click)="route('paidSubs')" class="routeLink">See all ></a>
                                    <!-- <div class="indicator green">61.8%</div> -->
                                </div>
                            </div>
                            <!-- <div class="chart" style="font-size: 35px;color:#707070;">$ {{amountCounter.toFixed(2)}}M</div> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="card small-cards">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="info">
                                <div class="d-flex flex-column">
                                    <h3 class="card-heading">Current MRR
                                        <!-- <span class="indicator green" style="margin-left: 5px;font-size: 13px;">20.13%</span> -->
                                    </h3>
                                    <h3 style="font-size: 35px;margin:0;color:#2c7be5;margin-bottom: 15px;">{{currentMRR | currency}}</h3>
                                    <a (click)="route('currentMrr')" class="routeLink" >See all ></a>
                                    <!-- <div class="indicator blue">29.8%</div> -->
                                </div>
                            </div>
                            <!-- <div class="chart" style="font-size: 35px;color:#2c7be5;">{{numberPercent}}%</div> -->
                        </div>
                    </div>
                </div>
               </div>


                <div class="card mb-20" style="padding: 30px;" *ngIf="featureControlService.showAnalytics && (tvAppAndroidTV || tvAppIOSTV || tvAppRoku || mobileAppAndroid || mobileAppIos)">
                    <h3 class="card-heading">App Launches</h3>
                    <p-chart type="line" *ngIf="tvAppAndroidTV || tvAppIOSTV || tvAppRoku || mobileAppAndroid || mobileAppIos"
                     width="99%" height="40vh" [responsive]="true" [data]="appLaunchesData" [options]="basicOptions2"></p-chart>
                    <!-- <p-chart type="bar" width="99%" height="30vh" [responsive]="true" [data]="stackedData" [options]="stackedOptions"></p-chart> -->
                    <!-- <div class="chart-wrapper">
                        <canvas baseChart
                        width="99%" height="30vh"
                            [datasets]="lineChartData"
                            [labels]="lineChartLabels"
                            [options]="lineChartOptions"
                            [colors]="lineChartColors"
                            [legend]="lineChartLegend"
                            [chartType]="lineChartType"
                            [plugins]="lineChartPlugins">
                        </canvas>
                    </div> -->
                    <div>
                        <a (click)="routeToAnalytic('appLaunches')" class="routeLink" >See all ></a>
                    </div>
                    <!-- <p style="margin: 0;padding: 0;"><span style="color: #2c7be5;">A payout for $ 921.42 was deposited 13 days ago.</span> Your next deposit is expected on Tuesday, March 13.</p> -->
                </div>

               <div class="cards-wrapper d-flex justify-content-between mb-20">
                <div [ngClass]="featureControlService.showSocialMedia ? 'col-lg-3 col-md-3 col-sm-12' :  'w-49' ">
                    <div class="card small-cards bg-img" style="background: url('assets/images/dashboard/corner-1.png');">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="info">
                                <div class="d-flex flex-column">
                                    <h3 class="card-heading">Contacts
                                        <!-- <span class="indicator brown" style="margin-left: 5px;font-size: 13px;">-0.23%</span> -->
                                    </h3>
                                    <h3 style="font-size: 35px;margin-bottm:15px;color:#f5803e;">{{Total_Contacts}}</h3>
                                    <a (click)="route('contact')" class="routeLink">See all ></a>
                                </div>
                            </div>
                            <div class="chart"></div>
                        </div>
                    </div>
                </div>
                <div  class="col-lg-3 col-md-3 col-sm-12" *ngIf="featureControlService.showSocialMedia">
                    <div class="card small-cards bg-img" style="background: url('assets/images/dashboard/corner-2.png');">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="info">
                                <div class="d-flex flex-column">
                                    <h3 class="card-heading">Leads
                                        <!-- <span class="indicator blue" style="margin-left: 5px;font-size: 13px;">-0.23%</span> -->
                                    </h3>
                                    <h3 style="font-size: 35px;margin-bottom:15px;color:#27bcfd;">{{Monthly_Lead_Count}}</h3>
                                    <a (click)="route('leads')" class="routeLink">See all ></a>
                                </div>
                            </div>
                            <!-- <div class="chart" style="font-size: 35px;margin:0;font-weight: 600;color:#707070;">$ {{amountCounter.toFixed(2)}}M</div> -->
                        </div>
                    </div>
                </div>
                <div [ngClass]="featureControlService.showSocialMedia ? 'col-lg-3 col-md-3 col-sm-12' :  'w-49' ">
                    <div class="card small-cards bg-img" style="background: url('assets/images/dashboard/corner-3.png');">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="info">
                                <div class="d-flex flex-column" *ngIf="showGivingCard">
                                    <h3 class="card-heading">Giving
                                        <!-- <span class="indicator green" style="margin-left: 5px;font-size: 13px;">-0.23%</span> -->
                                    </h3>
                                    <h3 style="font-size: 35px;margin-bottom:15px;color:#707070;">{{Monthly_Giving_Amount | currency}}</h3>
                                    <a (click)="route('giving')" class="routeLink">See all ></a>
                                </div>
                                <div class="d-flex flex-column" *ngIf="!showGivingCard">
                                    <h3 class="card-heading">Year to date MRR
                                        <!-- <span class="indicator green" style="margin-left: 5px;font-size: 13px;">-0.23%</span> -->
                                    </h3>
                                    <h3 style="font-size: 35px;margin-bottom:15px;color:#707070;">{{allRevenue}}</h3>
                                    <a (click)="route('yearMrr')" class="routeLink">See all ></a>
                                </div>
                            </div>
                            <!-- <div class="chart" style="font-size: 35px;margin:0;font-weight: 600;color:#707070;">{{numberPercent}}%</div> -->
                        </div>
                    </div>
                </div>
               </div>

               <!-- <div class="card mb-20">
                   <div class="d-flex justify-content-end">
                       <div (click)="showworldMap=!showworldMap" class="mr-10" [ngStyle]="{'background-color': showworldMap ? '#2c7be5' : '','color': showworldMap ? '#fff' : ''}" style="border: 1px solid lightgrey;padding: 15px;width: 99px;font-size: 16px;height: 50px;border-radius: 6px;color: grey;font-weight: 600;text-align: center;cursor: pointer;">World</div>
                       <div (click)="showworldMap=!showworldMap" [ngStyle]="{'background-color': !showworldMap ? '#2c7be5' : '', 'color': !showworldMap ? '#fff' : ''}" style="border: 1px solid lightgrey;padding: 15px;width: 99px;font-size: 16px;height: 50px;border-radius: 6px;color: grey;font-weight: 600;text-align: center;cursor: pointer;">State</div>
                   </div>
                <div class="d-flex">
                    <div class="col-10">
                        <div id="worldMapId" [ngStyle]="{'display': showworldMap ? 'block' : 'none'}" style="width: 100%; height: 50vh"></div>
                        <div  id="StateMapId" [ngStyle]="{'display': showworldMap ? 'none' : 'block'}" style="width: 100%; height: 50vh"></div>
                    </div>
                    <div class="col-2 position-relative">
                        <div style="position: absolute;top: 50%;left: 0;width: 100%;transform: translate(0%,-50%);padding: 0 15px;">
                            <h3 style="font-size: 16px;font-weight: 600;margin-bottom: 10px;text-decoration: underline;">Map Data</h3>
                            <ul class="" style="height:100%;">
                                <li class="d-flex justify-content-between" style="padding: 10px 0;">
                                    <div style="font-style: 12px;font-weight: 600;">India</div>
                                    <div style="font-style: 12px;font-weight: 600;color: #797979;">75</div>
                                </li>
                                <li class="d-flex justify-content-between" style="padding: 10px 0;">
                                    <div style="font-style: 12px;font-weight: 600;">China</div>
                                    <div style="font-style: 12px;font-weight: 600;color: #797979;">95</div>
                                </li>
                                <li class="d-flex justify-content-between" style="padding: 10px 0;">
                                    <div style="font-style: 12px;font-weight: 600;">United State</div>
                                    <div style="font-style: 12px;font-weight: 600;color: #797979;">73</div>
                                </li>
                                <li class="d-flex justify-content-between" style="padding: 10px 0;">
                                    <div style="font-style: 12px;font-weight: 600;">Canada</div>
                                    <div style="font-style: 12px;font-weight: 600;color: #797979;">92</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
               </div> -->

               <div class="cards-wrapper d-flex justify-content-between mb-20" *ngIf="featureControlService.showVOD">
                    <div  [ngClass]="featureControlService.showAnalytics ? 'col-3' : 'col-12'" >
                        <div class="card d-flex flex-column" style="height: 100%;">
                            <div class="header">
                                <h3 class="card-heading">Trending Videos</h3>
                            </div>
                            <ul class="users">
                                <li style="margin: 10px 0" *ngFor="let item of trendinglist">
                                    <div class="d-flex align-items-center">
                                        <div class="image-wrapper" style="margin-right:10px; background-color: grey;border-radius: 50%;">
                                            <img [src]="item.item.squareArtwork ? item.item.squareArtwork.document.newimg : ''"
                                            onload="style.opacity = 1" [ngStyle]="{'background-color': item.item.squareArtwork ? item.item.squareArtwork.document.imageColur : '#e6e6e6'}"
                                             style="opacity: 0;width: 50px;height: 50px;border-radius: 50%;cursor: pointer;" alt=""  (click)="routeToItems(item)">
                                        </div>
                                        <div class="user-info d-flex flex-column " >
                                            <h3 class="pointer" style="margin: 0;padding: 0;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 225px;"
                                            (click)="routeToItems(item)"
                                            >{{item.item.title}}</h3>
                                            <p style="margin: 0;padding: 0;color: #a1a1a1;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 225px;">{{item.item.mediaSeriesTitle}}</p>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="!trendinglist.length">
                                    No trending Data Exists
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-9" style="width: 66%;" *ngIf="featureControlService.showAnalytics">
                        <div class="card" style="height: 100%;">
                            <div class="d-flex jusitify-content-between">
                                <div class="d-flex flex-column">
                                    <h3 class="card-heading">Video Play Count</h3>
                                    <h1 style="color: #1b69b1;"><b class="d-flex jusitify-content-between align-items-center"><span style="font-size: 14px;color: #242E42;font-weight: 100;">Current Month Video Play Count :&nbsp;</span> {{mediaPlayTotalCount}}</b>
                                        <!-- <span style="margin-left: 10px;"class="indicator blue">5%</span> -->
                                    </h1>
                                </div>
                            </div>
                            <!-- <h2 style="font-size:16px;color:grey">Sales over time</h2> -->
                            <p-chart type="line" width="99%" height="30vh" [responsive]="true" [data]="basicData2" [options]="basicOptions2"></p-chart>
                            <div style="margin-top: 20px;">
                                <a (click)="this.router.navigate(['media-module/media/analytics'])" class="routeLink" class="routeLink">See all ></a>
                            </div>
                        </div>
                    </div>
               </div>

               <div class="cards-wrapper d-flex justify-content-between mb-20">
                   <div class="col-3">
                     <div class="card" style="height: 510px;" [ngClass]="{'mt-4': isMobView}">
                        <h3 class="card-heading">Subscribers</h3>
                        <div class="d-flex justify-content-around align-items-center mb-20" style="padding: 0 10px;">
                            <div class="d-flex flex-column align-items-center"><h3 style="font-size: 18px;font-weight: 600;padding: 0;margin:0">Free</h3><p style="font-size: 25px;padding: 0;margin:0" [style.color]="'#990033'">{{freeSubscriberCount}}</p></div>
                            <div class="d-flex flex-column align-items-center"><h3 style="font-size: 18px;font-weight: 600;padding: 0;margin:0">Trial</h3><p style="font-size: 25px;padding: 0;margin:0" [style.color]="'#003399'">{{trialSubscriberCount}}</p></div>
                            <div class="d-flex flex-column align-items-center"><h3 style="font-size: 18px;font-weight: 600;padding: 0;margin:0">Paid</h3><p style="font-size: 25px;padding: 0;margin:0" [style.color]="'#660066'">{{paidsubscriberCounts}}</p></div>
                        </div>
                         <p-chart #doughnutChart type="doughnut" width="99%" height="40vh" [responsive]="true" [data]="subscribedData" [options]="summaryChartOptions"></p-chart>
                        </div>
                   </div>
                   <div class="col-9" style="width: 66%;">
                    <div class="table-wrapper card mb-20" style="height: 510px;overflow-y: auto;">
                        <div class="header d-flex justify-content-between align-items-center" [ngClass]="{'flex-column': isMobileandTabView}">
                            <h3 class="card-heading mb-0" style="line-height: 1.1;">Transaction Summary</h3>
                            <div class="action" style="text-align: center;">
                                <!-- <select name="action" id="" style="padding: 10px;font-size: 12px;border: 1px solid lightgrey;border-radius: 7px;">
                                    <option value="action" selected>Users</option>
                                    <option value="archive">Giving</option>
                                    <option value="delete">One-time purchase</option>
                                </select> -->
                                <ul class="d-flex button-wrapper" [ngClass]="{'mt-3': isMobileandTabView,'flex-wrap': isMobileandTabView,'gap-1': isMobileandTabView}">
                                    <li class="buttons" (click)="activeTransaction('all')" [ngClass]="[showAllTransaction ? 'activeBtn': '']" >All</li>
                                    <li class="buttons" (click)="activeTransaction('user')" [ngClass]="[showUserTransaction ? 'activeBtn': '']" >Subscription</li>
                                    <li *ngIf="featureControlService.showGiving && this.profitable === false" class="buttons" (click)="activeTransaction('giving')" [ngClass]="[showGivingTransaction ? 'activeBtn': '']">Giving</li>
                                    <li class="buttons" (click)="activeTransaction('oneTime')" [ngClass]="[showOneTimeTransaction ? 'activeBtn': '']">One-time purchase</li>
                                </ul>
                            </div>
                        </div>
                        <ul style="margin: 0 !important;">
                            <li class="d-flex align-items-center" style="padding: 15px 0;" *ngFor="let item of transactionalSummary_data">
                                <div [ngStyle]="{'border-color': item.summaryType === 'PAID_SUBSCRIBER' || item.summaryType === 'USER_SUBSCRIPTION' ? '#039122': item.summaryType === 'GIVING_USERS' || item.summaryType === 'GIVING'? 'red': item.summaryType === 'ONE_TIME_PAID_SUBSCRIBER' || item.summaryType === 'MEDIA_ITEM' ? '#3f76dd' : ''}" style="border-left: 3px solid red;height:45px;margin-right:5px"></div>
                                <div class="img pointer" style="height: 50px;width: 50px;margin-right: 10px;border-radius: 5px;background-color: lightgray;font-size: 20px;display: flex;justify-content: center;align-items: center;text-transform: uppercase;font-weight: 800;">
                                    <span (click)="routeToUsers(item)" *ngIf="!item.userImg">{{item.userName ? item.userName.charAt(0) : ''}}</span>
                                    <img (click)="routeToUsers(item)" *ngIf="item.userImg" [src]="item.userImg" style="width: 100%;height: 100%;border-radius: 5px;" alt="">
                                </div>
                                <div class="d-flex flex-column" style="width: 55%;cursor: pointer;" (click)="routeToUsers(item)">
                                    <h2 style="font-size:16px;font-wight:600;margin: 0;padding: 0;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 300px;">{{item.userName}}</h2>
                                    <p style="font-size: 16px;font-weight: 100;color:grey;margin: 0;padding: 0;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 300px;">{{item.summaryType === 'PAID_SUBSCRIBER' ? 'Paid User': item.summaryType === 'GIVING_USERS' ? 'Giving': item.summaryType === 'ONE_TIME_PAID_SUBSCRIBER' ? 'One-time purchase' : ''}} <span *ngIf="item.summaryType === 'ONE_TIME_PAID_SUBSCRIBER' && item.mediaItemName">- {{item.mediaItemName}}</span></p>
                                </div>
                                <div class="d-flex flex-column align-items-center" style="width: 20%;text-align: center;">
                                    <div class="amount" style="padding: 0;margin: 0;font-size: 16px;font-weight: 600;">{{item.price ? (item.price | currency) : 0.00}} USD</div>
                                    <div class="date"  style="padding: 0;margin: 0;font-size: 14px;color:#a5a2a2;">{{item.paidDate ? (item.paidDate | date: 'dd MMM yyyy') : ''}}</div>
                                </div>
                                <div class="status" style="width: 20%;text-align: center;">
                                    <span style="padding: 10px;border-radius:7px;background-color: rgb(171, 219, 126);color:green">Completed</span>
                                </div>
                            </li>
                            <!-- <li *ngIf="transactionalSummary_data.length===0" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">
                                No Data Available
                            </li> -->
                            <li style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);" *ngIf="showLoader">
                                <div id="loader">
                                    <div id="spinner">
                                    </div>
                                  </div>
                            </li>
                            <li style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);" *ngIf="emptyData">
                                <h3>No transaction summary exists</h3>
                            </li>
                        </ul>
                        <div>
                            <a *ngIf="transactionalSummaryType !== 'ALL' && transactionalSummary_data.length>0" (click)="seeAllFromTransaction(transactionalSummaryType)" class="routeLink">See all ></a>
                        </div>
                   </div>
                   </div>
               </div>

            </div>
        </div>


        <!-- new dashboard end -->



        <!-- <div class="row" *ngIf="showPromotion" >
            <div class="col-12">
                <div class="dashboard-item text-center" >
                    <div class="promotion-img-wrapper" style="width: 100%; height: 100%;min-height: 320px;" [ngStyle]="{'background-color': promotionaImageBgColor ? promotionaImageBgColor : '#e6e6e6'}">
                        <a [href]="promotionalLink" target="_blank"><img style="width: 100%;"  onload="style.opacity = 1" style="background-color: #e6e6e6;opacity: 0; width: 100%;" [src]="promotionImage" alt=""></a>
                        <img class="cross-icon-img" style="cursor: pointer;" (click)="deleteImage(documentId)"
                src="./../../../../assets/images/library/close.svg" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-20 app-download mt-sm-10">

            <div class="col-lg-6">

                <div class="dashboard-item">
                    <p class="dashboard-title">App Downloads</p>

                    <p class="app-count">{{appDownloads}}</p>

                    <div class="col-md-12">
                        <div id="chartdownloaddiv" class="resp-chart" style="width: 100%;height: 300px;"></div>
                    </div>


                </div>

            </div>


            <div class="col-lg-6">

                <div class="dashboard-item">
                    <p class="dashboard-title">App Launches</p>

                    <p class="app-count">{{appLaunches}}</p>
                    <div class="col-md-12">
                        <div id="chartLaunchesdiv" class="resp-chart" style="width: 100%;height: 300px"></div>
                    </div>


                </div>
            </div>


        </div>







        <div class="row mt-20 mt-sm-10">
            <div class="col-lg-6">
                <div *ngIf="combined" routerLink="/media-module/media/on-demand/edit-media-item/{{mostRecentItemId}}"
                    [ngStyle]="{'background-image': imagePath ? 'url(' + imagePath + ') ' : 'url(' + '' + ')' }" style="background-position: center;"
                    class="dashboard-item video-bg">
                    <div class="video-info d-flex justify-content-between flex-column">
                        <div>
                            <p class="dashboard-title recent_text">Most Recent Item</p>
                        </div>
                        <div>
                            <h4>{{speaker}}</h4>
                            <p class="dashboard-title mb-0 f-20 recent_text">{{title}}<br>Added {{this.date}}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="!combined" class="dashboard-item video-bg">
                    <div class="video-info d-flex justify-content-between flex-column">
                        <div>
                            <p class="dashboard-title recent_text">No Recent Items</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">


                <div class="dashboard-item mt-sm-10">
                    <div class="d-flex justify-content-between">
                        <p class="dashboard-title">Trending</p>
                        <p class="dashboard-title">Past 30 days</p>
                    </div>


                    <table class="trending-table">
                        <tr *ngFor="let item of trendinglist; let i = index">
                            <td  class="d-flex align-items-center my-1">
                                <span style="color: #b8babf;" >{{i + 1}}.</span>
                                <span class="span-img">

                                    <img class="trend-img"
                                        [src]="item.item.squareArtwork ? item.item.squareArtwork.document.newimg : ''"
                                        onload="style.opacity = 1" style="opacity: 0;"
                                        [ngStyle]="{'background-color': item.item.squareArtwork ? item.item.squareArtwork.document.imageColur : '#e6e6e6'}"
                                        alt="">

                                </span>

                                <span style="cursor: pointer;" class="item-title"
                                    routerLink="/media-module/media/on-demand/edit-media-item/{{item.item.id}}">{{item.item.title}}</span>
                            </td>
                            <td  class="mx-4 my-1 ">
                                <span style="cursor: pointer;" class="series-title mt-1"
                                    routerLink="/media-module/media/on-demand/edit-media-series/{{item.item.mediaSeriesId}}">{{item.item.mediaSeriesTitle}}</span>

                            </td>
                            <td class="mx-4 my-1 " >
                                <em [appSvgIcon]="'bar'"
                                                    tooltip="Plays - {{item.playCount}}" container="body"></em>
                            </td>
                        </tr>
                        <tr *ngIf="!trendinglist.length">
                            No trending Data Exists</tr>
                    </table>



                </div>

            </div>


        </div>
        <div class="row mt-20 r-media-subs">

            <div class="col-sm-6">
                <div class="dashboard-item">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-between align-items-center">
                            <div>
                                <div>
                                    <span routerLink="/media-module/media/analytics" class="d-flex align-items-center"
                                        style="cursor: pointer;">
                                        <p class="dashboard-title mb-1 text-center">Media Plays</p>
                                        <img class="arrow_img mx-2"
                                            src="./../../../assets/images/library/right-arrow.svg" alt="arrow">
                                    </span>
                                    <p *ngIf="mediaPlayCount" class="media-count mb-2">{{mediaPlayCount}}</p>
                                    <p *ngIf="!mediaPlayCount" class="media-count mb-2">0</p>
                                </div>
                                <div>
                                    <p class="dashboard-title mb-1 ">Past 30 days</p>

                                    <p *ngIf="pastDaysCount" class="media-count mb-2">{{pastDaysCount}}</p>
                                    <p *ngIf="!pastDaysCount" class="media-count mb-2">0</p>
                                </div>
                            </div>
                            <p-chart type="doughnut" [data]="playCountData" [options]="summaryChartOptions"></p-chart>

                        </div>

                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="dashboard-item mt-sm-10">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-between align-items-center">
                            <div>
                                <div>
                                    <p class="dashboard-title mb-1 "> Paid Subscribers</p>
                                    <p class="media-count mb-2">{{paidsubscriberCounts}}</p>
                                </div>
                                <div>
                                    <p class="dashboard-title mb-1 text-center"> Free Subscribers</p>

                                    <p class="media-count mb-2">{{freeSubscriberCount}}</p>
                                </div>
                            </div>
                            <p-chart type="doughnut" [data]="subscribedData" [options]="summaryChartOptions"></p-chart>

                        </div>

                    </div>
                </div>
            </div>


        </div> -->

    </ng-container>
</div>
