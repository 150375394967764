<div class="row">
  <div class="app-filters">
    <div class="d-inline-block position-relative crtfldr"></div>
    <div class="app-search app-search-big align-custom align-bar">
      <button type="button" class="btn btn-white button show-popover" (click)="openCustomPlan()">Create Custom
        Plan</button>
    </div>

    <div class="app-search">
      <input type="text" placeholder="Search" class="form-control w-100" #searchInput>

    </div>
  </div>
</div>


<!-- CARD FORM -->
<!-- <div class="app-block card-wrapper">
  <div class="scrollable-container">
    <div class="subcription-card subscription-block" *ngFor="let item of ListPlanDetails">
    <div>
      <div class="plan-name">
            <div class="recent-item-icons" style="position: absolute;left: 93%;margin-top: 28px;">
              <div class="d-flex align-items-center">
                <div class="position-relative action-icons" >
              <div class="btn-group align-items-center" dropdown>
                            <span class="pointer toggle-dropdown" dropdownToggle><em [appSvgIcon]="'toggle'"></em></span>
                            <ul  class="dropdown-menu dropdown-menu-right" role="menu">
                              <li (click)="openDeleteModal()"><a>Delete</a></li>
                            </ul>
              </div>
                </div>
              </div>
            </div>
        <p>{{item.subscriptionPlanName}}</p>
        <h2><span>$</span>{{item.planAmount}}</h2>
      </div>
      <div class="plan-feature">
        <p>{{item.storageValue ? item.storageValue : ''}}</p>
        <ul *ngFor="let data of item.permission">
          <div class="d-flex align-items-center">
            <i class="fa fa-check " aria-hidden="true"></i>
            <li *ngIf="data.isView">{{data.name}}</li>
          </div>
        </ul>
      </div>
    </div>
   </div>
  </div>
</div> -->
<!-- CARD FORM -->



<!-- TABULAR FORM -->

<div class="app-block app-block-form1 video-item">
  <div class="app-block-title" style="position: relative;">
    <p class="header-title" style="position: absolute;left: 2%;">Plan ID</p>
    <p class="header-title" style="position: absolute;left: 12%;">Plan Name</p>
    <p class="header-title" style="position: absolute;left: 32%;">Trial Days</p>
    <p class="header-title" style="position: absolute;left: 54%;">Price</p>
    <p class="header-title" style="position: absolute;left: 72%;">Template</p>
    <p class="header-title" style="position: absolute;left: 91%;">Action</p>
  </div>
  <ul class="recent-item-list m-0">
    <li *ngFor="let item of ListPlanDetails" style="position: relative;">
      <!-- <div class="d-flex align-items-center pointer route-to-width"> -->
      <div class="p-2" style="position: absolute;left: 1.5%;    margin-top: 21px;
" (click)="gotoView(item)">
        <p class="pointer m-0">
          {{item.subscriptionPlanId}}</p>
      </div>
      <div class="p-2" style="position: absolute;left: 11.5%;    margin-top: 21px;
" (click)="gotoView(item)">
        <p class="pointer ellipsis-text-content m-0" title="{{item.subscriptionPlanName}}">
          {{item.subscriptionPlanName}}</p>
      </div>
      <div class="p-2 series-position" style="position: absolute;left: 33.5%;    margin-top: 21px;
" (click)="gotoView(item)">
        <p class="pointer title-container m-0">{{item.trialDays}}</p>
      </div>
      <div class="p-2 plan-position" style="position: absolute;left: 53.5%;    margin-top: 21px;
" (click)="gotoView(item)">

        <p class="pointer ellipsis-text-content title-container m-0">${{item.planAmount}}</p>

      </div>
      <div class="p-2 plan-position" style="position: absolute;left: 71.3%;    margin-top: 21px;
      " (click)="gotoView(item)">

        <p class="pointer ellipsis-text-content title-container m-0 email-template-text" [title]="item.emailTemplateName ?
        item.emailTemplateName : '--'">
          {{item.emailTemplateName ?
          item.emailTemplateName : '--' }}
        </p>

      </div>
      <!-- <div class="p-2 plan-position" > -->
      <div class="recent-item-icons" style="position: absolute;left: 90.5%;margin-top: 25px;">

        <div class="d-flex align-items-center">

          <!-- <div class="position-relative action-icons">
                  <em [appSvgIcon]="'edit2'" (click)="gotoView(item)" tooltip="Edit" container="body"></em>
                  <em [appSvgIcon]="'delete'" tooltip="Delete" [ngClass]="[ (item.subscriptionPlanName === 'Basic' || item.subscriptionPlanName === 'Standard' || item.subscriptionPlanName === 'Premium') ? 'disable'  : '' ]"
                  (click)="openDeleteModal(item)"></em>
                </div> -->
          <div class="position-relative action-icons">



            <div class="btn-group align-items-center dropdown" dropdown>

              <em [appSvgIcon]="'edit2'" (click)="gotoView(item)" tooltip="Edit" container="body"></em>

              <span class="pointer toggle-dropdown" dropdownToggle><em [appSvgIcon]="'toggle'"></em></span>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">

                <div style="display: none;">
                  <span class="app-password app-copy">
                    <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="signUpFormUrl" readonly #urlInput>
                  </span>
                </div>

                <li (click)="copyUrl(urlInput,item.subscriptionPlanId)"><a>Copy Signup Url</a>

                </li>
                <!-- *ngIf="item.subscriptionPlanName !== 'Basic' && item.subscriptionPlanName !== 'Premium' && item.subscriptionPlanName !==
                  'Standard'" -->
                <li (click)="openSubcribedCustomers(item)"><a>Subscribed Customers</a>

                </li>
                <li
                  *ngIf="item.subscriptionPlanName !== 'Basic' && item.subscriptionPlanName !== 'Premium' && item.subscriptionPlanName !== 'Standard'">
                  <a (click)="openDeleteModal(item)" style="width: 120px;">

                    Delete
                  </a>
                </li>
                <li class="p-10" style="padding:10px !important"
                  (click)="templatePopup = true; subscriptionId = item.subscriptionPlanId; templateId = item.emailTemplateId;">
                  Select Template
                </li>
                <li style="padding:10px !important"
                  (click)="templateModal = true; getTemplateContent(item.emailTemplateId);">Template Preview</li>

              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="recent-item-icons" style="position: absolute;left: 85%;top: 34%;">

              <div class="d-flex align-items-center">

                <div class="position-relative action-icons">
                  <em [appSvgIcon]="'edit2'" (click)="gotoView(item)" tooltip="Edit" container="body"></em>
                  <em [appSvgIcon]="'delete'" tooltip="Delete" [ngClass]="[ (item.subscriptionPlanName === 'Basic' || item.subscriptionPlanName === 'Standard' || item.subscriptionPlanName === 'Premium') ? 'disable'  : '' ]"
                  (click)="openDeleteModal(item)"></em>








                </div>

              </div>
          </div> -->
      <!-- </div> -->

      <!-- </div> -->


    </li>
    <li *ngIf="!ListPlanDetails.length" class="no-media">
      <div class="d-flex align-items-center">
        <div class="recent-info">
          <span>No Plan Available.</span>
        </div>
      </div>
    </li>

  </ul>

  <div class="d-flex justify-content-between align-items-center paginator-top w-100">

    <p-paginator class="custom_paginator" [rows]="rowCount" [showCurrentPageReport]="true" [first]="first"
      currentPageReportTemplate="Showing {first} - {last} of {{totalRecords}}" [rowsPerPageOptions]="[10,20,25,50, 100]"
      (onPageChange)="loadCustomers($event)" [totalRecords]="totalRecords" pageLinkSize="3"></p-paginator>

  </div>
</div>
<!-- TABULAR FORM -->

<p-dialog *ngIf="deleteModal" header="Delete Subscription Plan" [(visible)]="deleteModal" [modal]="true"
  [draggable]="false" [resizable]="false">
  <div>
    Are you sure, you want to delete <span>{{selectedSubscriptionName}}
    </span> ? This cannot be undone.
  </div>
  <p-footer>
    <button type="button" class="btn btn-white" (click)="deleteSubscription()">Yes, Delete</button>
    <button type="button" class="btn btn-white" (click)="deleteModal = false">No, Cancel</button>
  </p-footer>
</p-dialog>
<p-dialog *ngIf="subcribedCustomers" header="Subscribed Customers" [(visible)]="subcribedCustomers" [modal]="true"
  [style]="{ 'width': '50%' , 'padding': '30px' }">

  <p-table [columns]="cols" [value]="allSubscribedCustomers" class="tmplttable"
    styleClass="tmplttable custom-dataTable table-striped">

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="color: #656565;text-align: center;" [style.width.px]="col.field === 'mobile' ? 145 : 'auto'"
          *ngFor="let col of columns" [class.template-name]="col.field === 'subscribers'">
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td *ngFor="let col of columns"
          [ngClass]="col.field === 'email' || col.field === 'subscribers'  ? 'customer-name' : '' ">
          <span class="mx-4" *ngIf="col.field === 'id'">{{rowData.id}}</span>

          <span class="mx-4" [tooltip]="rowData.name" *ngIf="col.field === 'subscribers'">{{rowData.name}}</span>
          <span class="mx-4" *ngIf="col.field === 'mobile'">{{rowData.mobile}}</span>
          <span class="mx-4" [tooltip]="rowData.email" *ngIf="col.field === 'email'">{{rowData.email}}</span>


        </td>
      </tr>
    </ng-template>
    <!-- <div class="d-flex justify-content-between align-items-center paginator-top w-100"> -->



    <!-- <tr class="d-flex justify-content-between align-items-center paginator-top w-100"> -->
    <ng-template pTemplate="footer">
      <!-- <tr class="d-flex justify-content-between align-items-center paginator-top w-100"> -->
      <!-- <span class="d-flex justify-content-between align-items-center paginator-top w-100">
    <p-paginator class="custom_paginator" [rows]="rows2" [showCurrentPageReport]="true" [first]="first2"
      currentPageReportTemplate="Showing {{first2}} - {last} of {totalRecords}" (onPageChange)="loadAssociatedOrg($event)"
      [totalRecords]="totalRecords"></p-paginator>
          </span>  -->

      <!-- </tr> -->



    </ng-template>
    <!-- </tr> -->

    <!-- </div> -->

    <!--
  <div class="d-flex justify-content-between align-items-center paginator-top w-100"> -->



    <!-- </div> -->
  </p-table>
  <span class="d-flex justify-content-between align-items-center paginator-top w-100">
    <p-paginator class="custom_paginator" [rows]="rows2" (onPageChange)="loadAssociatedOrg($event)" pageLinkSize="3"
      [showCurrentPageReport]="true" [first]="first2"
      currentPageReportTemplate="Showing {{first2}} - {last} of {{totalRecordsAssociatedOrg}}"
      [totalRecords]="totalRecordsAssociatedOrg"></p-paginator>
  </span>
</p-dialog>

<p-dialog *ngIf="templatePopup" header="Select Template" [(visible)]="templatePopup" [modal]="true"
  [style]="{width: '75vw'}" [draggable]="false" [resizable]="false">
  <div>
    <app-subscription-template [subscriptionID]="subscriptionId" [templateId]="templateId"
      (updateTemplatePopup)="getUpdateState($event)"></app-subscription-template>
  </div>
</p-dialog>


<p-dialog *ngIf="templateModal" header="Template Preview" [(visible)]="templateModal" [modal]="true" [draggable]="false"
  [resizable]="false">
  <div class="radio-group">
    <div class="form-check">
      <input class="form-check-input" type="radio" name="template" id="desktop" checked
        (change)="templateView = 'desktop'">
      <label class="form-check-label" for="desktop">
        Desktop
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="template" id="mobile" (change)="templateView = 'mobile'">
      <label class="form-check-label" for="mobile">
        Mobile
      </label>
    </div>
  </div>
  <div class="template-preview" [style.width]="templateView === 'desktop' ? '1024px' : '600px'">
    <div [innerHTML]="templateContent | safe"></div>
  </div>
</p-dialog>


<!-- <div class="">
                                        <div class="subscription-block " routerLink="/subscription/edit-subscription/2/Standard">
                                            <div>
                                              <div class="plan-name">
                                                  <p>Standard</p>
                                                  <h2><span>$</span>{{planAmountStandard}}</h2>
                                              </div>
                                              <div class="plan-feature">
                                                  <p>1TB Cloud Storage (VOD)</p>
                                                  <ul *ngFor="let item of standardFeature">
                                                    <div class="d-flex align-items-center">
                                                      <i class="fa fa-check " aria-hidden="true"></i>
                                                      <li *ngIf="item.isView"  >{{item.name}}</li>
                                                  </div>
                                                  </ul>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <div class="subscription-block" routerLink="/subscription/edit-subscription/3/Premium">
                                            <div>
                                              <div class="plan-name">
                                                  <p>Premium</p>
                                                  <h2><span>$</span>{{planAmountPremium}}</h2>
                                              </div>
                                              <div class="plan-feature">
                                                  <p>5TB Cloud Storage (VOD)</p>
                                                  <ul *ngFor="let item of premiumFeature">
                                                    <div class="d-flex align-items-center">
                                                      <i class="fa fa-check " aria-hidden="true"></i>
                                                      <li *ngIf="item.isView"  >{{item.name}}</li>
                                                  </div>
                                                  </ul>
                                              </div>
                                            </div>
                                        </div>
                                    </div> -->