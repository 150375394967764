<div class="main-div">
    <div class="sub-main" [ngStyle]="{'background-color':(brandColor)? brandColor: '#010433' }">
        <div class="nowcast-container" [ngStyle]="{'background-color':(brandColor)? brandColor: '#010433' }">
            <div class="nowcast-container-wrapp">
                <div class="logo">
                    <img [src]="logoSrcFound ? logoId : defaultSrc" alt="logo">
                </div>
                <div class="login-form app-block-small-form" *ngIf="!showThanks && !showToken">
                    <form autocomplete="off" #resetPasswordForm="ngForm">
                        <div class="login-password">
                            <label>Password</label>
                            <span class="app-password">
                                <input [type]="passwordType ? 'text' : 'password'" class="form-control" name="password"
                                    autocomplete="new-password" required #password="ngModel"
                                    [(ngModel)]="resetPassword.password" minlength="6">
                                <em class="password-icon" [class.view]="passwordType" *ngIf="!passwordType"
                                    [appSvgIcon]="'crossEyeIcon'" (click)="passwordType = !passwordType"></em>
                                <em class="password-icon" [class.view]="passwordType" *ngIf="passwordType"
                                    [appSvgIcon]="'EyeIcon'" (click)="passwordType = !passwordType"></em>
                            </span>
                            <!-- <ng-container *ngIf="password.errors && (password.touched || password.dirty)">
                                <p class="error" [hidden]="!password.errors.required">Password is required</p>
                                <p class="error" [hidden]="!password.errors.minlength">Password should be of minimum 6
                                    characters</p>
                            </ng-container> -->
                        </div>
                        <div class="login-password">
                            <label>Confirm Password</label>
                            <span class="app-password">
                                <input [type]="confirmPasswordType ? 'text' : 'password'" class="form-control"
                                    name="confirmPassword" autocomplete="new-password" required
                                    #confirmPassword="ngModel" [(ngModel)]="resetPassword.confirmPassword"
                                    minlength="6">
                                <em class="password-icon" [class.view]="confirmPasswordType"
                                    *ngIf="!confirmPasswordType" [appSvgIcon]="'crossEyeIcon'"
                                    (click)="confirmPasswordType = !confirmPasswordType"></em>
                                <em class="password-icon" [class.view]="confirmPasswordType" *ngIf="confirmPasswordType"
                                    [appSvgIcon]="'EyeIcon'" (click)="confirmPasswordType = !confirmPasswordType"></em>
                            </span>
                            <!-- <ng-container
                                *ngIf="confirmPassword.errors && (confirmPassword.touched || confirmPassword.dirty)">
                                <p class="error" [hidden]="!confirmPassword.errors.required">Confirm Password is
                                    required</p>
                                <p class="error" [hidden]="!confirmPassword.errors.minlength">
                                    Confirm Password should be of minimum 6 characters
                                </p>
                            </ng-container> -->
                            <!-- <ng-container
                                *ngIf="!confirmPassword.errors && !password.errors && resetPassword.password && resetPassword.confirmPassword && (resetPassword.password !== resetPassword.confirmPassword)">
                                <p class="error">Password does not match</p>
                            </ng-container> -->
                        </div>
                        <div class="button-div">
                            <button type="button" class="submit-btn" (click)="reset()"
                                [ngClass]="{disable: !resetPassword.password || !resetPassword.confirmPassword}">
                                <span *ngIf="router.url.includes('/reset-password')">Request Password</span>
                                <span *ngIf="router.url.includes('/set-password')">Set Password</span>
                            </button>
                        </div>

                    </form>
                </div>

                <div *ngIf="showThanks && !showToken" class="thanks-wrapper">
                   <h4 style="color: #a7a7a7;font-size: 24px;font-weight: 500;" *ngIf="router.url.includes('/reset-password')">Your password has been reset successfully</h4>

                    <h1 *ngIf="router.url.includes('/set-password')">Thank you !</h1>
                    <div class="detail">
                        <!-- <h3>Experience Nowcast Apps Network across all platforms</h3> -->
                        <h3>{{fullText}}</h3>
                        <p>{{fullTextDescription}}</p>
                        <!-- <p>Nowcast Apps is accessible on over 1000 devices including iOS devices, Android devices, MACs,
                            PCs, & streaming media boxes such as Roku.</p> -->
                    </div>
                    <div>
                        <h3 *ngIf="created === 'org'" class="login" routerLink="/login">
                            Login to continue.
                        </h3>
                        <p *ngIf="created === 'user'" class="user-login">To continue, Please login to {{orgName}}
                           <span class="pointer underline" *ngIf="showWebsite" (click)="openLink()">website</span><span *ngIf="showWebsite"> or</span>  mobile app.</p>
                    </div>
                </div>

                <div *ngIf="showToken" class="thanks-wrapper">
                    <!-- <h1>Thank you !</h1> -->
                    <div class="detail">
                        <!-- <h3>Experience {{orgName}} Network across all platforms</h3>>
                        <p>{{orgName}} is accessible on over 1000 devices including iOS devices, Android devices, MACs, PCs, & streaming media boxes such as Roku.</p> -->
                        <!-- <p>You've successfully signed up for the organization. In order to get started,please check your mail </p> -->
                        <p>Token is expired</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>