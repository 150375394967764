import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SharedService } from 'src/app/services/shared/shared.service';
import videojs from 'video.js';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    controls: boolean;
    radiusClass: string;
    sources: {
      src: string;
    }[];
  };
  @Input() pauseTime: number;
  @Input() currentVedio: any;
  @Input() fromLiveStream: boolean;
  @Input() playerValidation: boolean;
  @Input() poster: any;
  @Output() completeVideoAction: EventEmitter<any>;
  @Output() playedAction: EventEmitter<any>;
  @Output() languageChangedAction: EventEmitter<any>;
  @Output() resolutionChangeAction: EventEmitter<any>;
  player: videojs.Player;
  radiusClass: string;
  constructor(
    private _SharedService: SharedService
  ) {
    this.currentVedio = { src: '' };
    this.playerValidation = false;
    this.pauseTime = 0;
    this.completeVideoAction = new EventEmitter<any>();
    this.playedAction = new EventEmitter<any>();
    this.languageChangedAction = new EventEmitter<any>();
    this.resolutionChangeAction = new EventEmitter<any>();
  }




  ngOnInit(): void {
    // console.log(this.options);    
    if (this.fromLiveStream) {
      console.log('from livestream');
      this.player = videojs(
        this.target.nativeElement,
        {
          ...this.options,
          controlBar: {
            pictureInPictureToggle: false,
            currentTimeDisplay: false,
            durationDisplay: false,
            fullscreenToggle: true,
            progressControl: false,
            playToggle: true,
            volumePanel: true,
            seekToLive: false,
            timeDivider: false,
            customControlSpacer: false,
            audioTrackButton: false,
            remainingTimeDisplay: false,
            liveDisplay: false


          },
          responsive: true
        },
        // function onPlayerReady() {
        // }
      );
    }
    else {
      console.log('not from livestream');
      this.player = videojs(
        this.target.nativeElement,
        {
          ...this.options,
          controlBar: {
            pictureInPictureToggle: false,
            currentTimeDisplay: false,
            durationDisplay: false,
            fullscreenToggle: false,
            progressControl: false,
            playToggle: false,
            volumePanel: true,
            seekToLive: false,
            timeDivider: false,
            customControlSpacer: false,
            audioTrackButton: false,
            remainingTimeDisplay: false,

          },
          responsive: true
        },
        function onPlayerReady() {
          // console.log('onPlayerReady', this);
        }
      );
    }
    this.player.aspectRatio('16:9');
    this.player.responsive(true);
    if (this.options.autoplay) {
      this.player.play();
    }
    if (this.options.radiusClass) {
      this.radiusClass = this.options.radiusClass
    }
    this.player.poster(this.poster);
    this.player.controls(this.options.controls);
    this.pauseTime && this.player.currentTime(this.pauseTime);
    this.player.on('error', (error) => {
      this._SharedService.updateLoader(true);
      this.resolutionChangeAction.emit(error);
      this._SharedService.updateLoader(false);
      // console.log(error);
    });
    console.log(this.fromLiveStream);
  }

  ngOnChanges(): void {
    if (this.player) {
      this.player.poster(this.poster);
      this.player.controls(this.options.controls);
      this.player.src(this.options.sources[0]);
      this.pauseTime && this.player.currentTime(this.pauseTime);
      if (this.options.autoplay) {
        this.player.play();
      }
    }
  }
  handleError(event: any) {
    console.log(event);
  }
  playerAction(event: any) {
    this.playedAction.emit(event);
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

}
